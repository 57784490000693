import { EditorProps } from "@tiptap/pm/view";
import invariant from "tiny-invariant";
import {
  CommodityTo,
  ReportCommodityToRecommendation,
  ReportTo,
} from "@/generated/api/news";
import { z } from "zod";
import { uploadImage } from "./editor.api";
import { unified } from "unified";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import { getHTML } from "@/common/utils";
import { DateTime } from "luxon";

type FormValues = {
  headline: string;
  dateFrom: string;
  dateTo: string;
  summary: string;
  language: string;
  commodities: {
    commodityId: number;
    headline: string;
    summary: string;
    recommendation: string;
    recommendationText: string;
  }[];
  // used for edit mode
  imagePreview?: {
    guid: string;
    url: string;
  };
};

type FormValuesWithImage = FormValues & { image?: File };

const commoditySchema = z.object({
  commodityId: z.number().int(),
  headline: z.string(),
  summary: z.string(),
  recommendationText: z.string(),
  recommendation: z.enum(
    Object.keys(ReportCommodityToRecommendation) as [
      keyof typeof ReportCommodityToRecommendation,
    ],
  ),
});

const reportFormSchema = z.object({
  dateFrom: z.string().regex(/[0-9]{4}-[0-9]{2}-[0-9]{2}/),
  dateTo: z.string().regex(/[0-9]{4}-[0-9]{2}-[0-9]{2}/),
  headline: z.string(),
  summary: z.string().optional(),
  image: z.instanceof(File).optional(),
  commodities: z.array(commoditySchema).min(1),
  language: z.string(),
});

const reportPayloadSchema = reportFormSchema.omit({ image: true }).extend({
  imageId: z.string().optional(),
});

type ReportPayload = z.infer<typeof reportPayloadSchema>;

const getReportPayload = async (data: FormValuesWithImage) => {
  const { image, ...rest } = data;
  const payload = structuredClone(rest) as ReportPayload;

  if (image && image.name.length > 0) {
    const uploaded = await uploadImage(image);
    payload.imageId = uploaded.guid;
  }

  const safePayload = reportPayloadSchema.parse(payload);

  for (const commodity of safePayload.commodities) {
    commodity.summary = await getMarkdownPayload(commodity.summary);
    commodity.recommendationText = await getMarkdownPayload(
      commodity.recommendationText,
    );
  }

  return safePayload;
};

const getMarkdownPayload = async (input: string) => {
  const html = await getHTML(input);
  const htmlWithImages = await getHTMLWithImages(html);
  const markdown = await getMarkdown(htmlWithImages);

  return markdown;
};

const getMarkdown = async (html: Document): Promise<string> => {
  const markdown = await unified()
    .use(rehypeParse)
    .use(rehypeRemark)
    .use(remarkStringify)
    .process(html.body.innerHTML);

  return String(markdown);
};

const getHTMLWithImages = async (doc: Document) => {
  const images = doc.body.querySelectorAll("img");

  for (const image of images) {
    if (!image.src.startsWith("blob:")) {
      // do no process images coming from the internet
      continue;
    }
    const blob = await (await fetch(image.src)).blob();
    const uploaded = await uploadImage(blob);
    image.src = uploaded.url;
  }

  return doc;
};

// https://www.codemzy.com/blog/tiptap-drag-drop-image
const handleImageDrop: EditorProps["handleDrop"] = (
  view,
  event,
  _slice,
  moved,
) => {
  if (
    !moved &&
    event.dataTransfer &&
    event.dataTransfer.files &&
    event.dataTransfer.files[0]
  ) {
    const file = event.dataTransfer.files[0];
    if (file.type.startsWith("image/")) {
      const img = document.createElement("img");
      img.src = window.URL.createObjectURL(file);
      const { schema } = view.state;
      const coordinates = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });
      const node = schema.nodes.image.create({ src: img.src });
      // eslint-disable-next-line lingui/no-unlocalized-strings
      invariant(coordinates, "coordinates are not defined");
      const transaction = view.state.tr.insert(coordinates.pos, node);

      return view.dispatch(transaction);
    }
    return true;
  }
  return false;
};

const getInitialValues = (
  from: ReportTo | undefined,
  commodities: CommodityTo[],
): FormValues => {
  const values: FormValues = {
    dateFrom: from?.dateFrom ?? DateTime.now().toISODate(),
    dateTo: from?.dateTo ?? DateTime.now().plus({ days: 7 }).toISODate(),
    language: from?.language ?? "",
    headline: from?.headline ?? "",
    summary: from?.summary ?? "",
    commodities: [],
    imagePreview: from?.image,
  };

  commodities.forEach((commodity) => {
    const reportCommodity = from?.commodities.find(
      (c) => c.commodity.id === commodity.id,
    );
    values.commodities.push({
      recommendation: reportCommodity?.recommendation ?? "",
      recommendationText: reportCommodity?.recommendationText ?? "",
      summary: reportCommodity?.summary ?? "",
      headline: reportCommodity?.headline ?? "",
      commodityId: commodity.id,
    });
  });

  return values;
};

export { handleImageDrop, getReportPayload, getInitialValues };
export type { ReportPayload, FormValues, FormValuesWithImage };
