import { Dialog } from "@/common/components/dialog/dialog";
import { useToggle } from "@/common/hooks/use-toggle";
import { getUser } from "@/common/services/user";
import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/macro";
import React from "react";
import invariant from "tiny-invariant";

const CompanyEditContext = React.createContext<
  { open: () => void; close: () => void } | undefined
>(undefined);

const useCompanyEditProvider = () => {
  const ctx = React.useContext(CompanyEditContext);

  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "CompanyEditContext only available within <ContratSignGuard />",
  );

  return ctx;
};

const CompanyEditGuard = ({ children }: React.PropsWithChildren) => {
  const dialog = useToggle();

  return (
    <CompanyEditContext.Provider
      value={{ open: dialog.setOn, close: dialog.setOff }}
    >
      {children}
      {dialog.on ? <CompanyEditGuardDialog /> : null}
    </CompanyEditContext.Provider>
  );
};

const CompanyEditGuardDialog = () => {
  const { close } = useCompanyEditProvider();

  return (
    <Dialog>
      <CompanyEditGuardDialogPartial onClose={close} />
    </Dialog>
  );
};

const CompanyEditGuardDialogPartial = ({
  onClose,
}: {
  onClose: () => void;
}) => (
  <>
    <Dialog.Title>
      <Trans>Akci nelze dokončit</Trans>
      <Dialog.Content>
        <Trans>Nemáte dostatečné oprávnění.</Trans>
      </Dialog.Content>
      <Dialog.Content>
        <Trans>
          Kontaktujte podporu{" "}
          <a href="mailto:help@cleverfarm.ag">help@cleverfarm.ag</a>.
        </Trans>
      </Dialog.Content>
    </Dialog.Title>
    <Dialog.Actions>
      <Dialog.Confirm onClick={onClose}>
        <Trans>Zavřít</Trans>
      </Dialog.Confirm>
    </Dialog.Actions>
  </>
);

const useCompanyEditGuard = <T = () => void,>({
  action,
}: {
  action: T;
}): T | (() => void) => {
  const { open: raiseIssue } = useCompanyEditProvider();
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);
  const company = user["can/app_metadata"]?.companies?.at(0);

  const canEdit =
    company?.grants.includes("COMPANY_DETAIL_EDIT") &&
    company.approvalState === "APPROVED";

  if (canEdit) {
    return action;
  }

  return raiseIssue;
};

export {
  useCompanyEditGuard,
  useCompanyEditProvider,
  CompanyEditGuard,
  CompanyEditGuardDialogPartial,
};
