import { block, getUser, unblock } from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";

const userQuery = ({ ssoUserId }: { ssoUserId: string }) =>
  queryOptions({
    queryKey: ["user", ssoUserId],
    queryFn: ({ signal }) => getUser(ssoUserId, { signal }),
  });

const useBlockUserMutation = () =>
  useMutation({
    mutationFn: ({
      ssoUserId,
      blocked,
    }: {
      ssoUserId: string;
      blocked: boolean;
    }) =>
      blocked
        ? block(encodeURIComponent(ssoUserId))
        : unblock(encodeURIComponent(ssoUserId)),
  });
export { userQuery, useBlockUserMutation };
