const isMatchingSearch = (
  storage: {
    mark: boolean;
    offers: Array<{
      crop: {
        mark: boolean;
      };
    }>;
  },
  search?: string,
) => {
  if (!search) {
    return true;
  }
  const cropNameMatch = storage.offers.some((offer) => offer.crop.mark);
  const storageNameMatch = storage.mark;

  return cropNameMatch || storageNameMatch;
};

const hasMatch = (a: string | undefined | null, search?: string) => {
  if (!(a && search)) {
    return false;
  }
  return a.toLowerCase().includes(search.toLowerCase());
};

export { isMatchingSearch, hasMatch };
