import BgPattern from "../../../assets/bg-pattern.svg?url";

const BackgroundContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <div
      className="flex h-full w-full items-center justify-center px-6 sm:px-0"
      style={{ background: `url(${BgPattern})` }}
    >
      {children}
    </div>
  );
};

const Box = ({ children }: React.PropsWithChildren) => (
  <div className="bg-white pb-1 sm:h-auto sm:w-[423px] sm:rounded-2xl sm:px-6 sm:shadow-can-light-box">
    {children}
  </div>
);

BackgroundContainer.Box = Box;

export { BackgroundContainer };
