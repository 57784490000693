import { useAuth0 } from "@auth0/auth0-react";

// Right now, I can't see a better way to get hold of this outside of React app
let getAccessTokenSilently: ReturnType<
  typeof useAuth0
>["getAccessTokenSilently"];

const setGetAccessTokenSilently = (fn: typeof getAccessTokenSilently) => {
  getAccessTokenSilently = fn;
};

export { getAccessTokenSilently, setGetAccessTokenSilently };
