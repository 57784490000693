import { graphqlClient } from "@/api/graphql";
import { numberFormatter } from "@/common/services/formatter";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

// eslint-disable-next-line lingui/no-unlocalized-strings
const offerQueryDocument = graphql(`
  query Offer($id: ID!) {
    offer(id: $id) {
      yearHarvested
      crop {
        id
        name
      }
      id
      parameters {
        edges {
          node {
            id
            ... on SelectOfferParameter {
              option {
                id
                title
                __typename
              }
            }
            ... on FloatOfferParameter {
              value
              cropParameter {
                unit
              }
            }
            cropParameter {
              id
              title
            }
          }
        }
      }
      amount
      totalAmount
      storage {
        id
        label
        address
        company {
          id
          name
        }
      }
    }
  }
`);

// eslint-disable-next-line lingui/no-unlocalized-strings
const offerPricesQueryDocument = graphql(`
  query OfferPrices($id: ID!, $currency: Currency!, $cropIds: [ID!]) {
    offer(id: $id) {
      storage {
        lastPrices(cropIds: $cropIds) {
          edges {
            node {
              id
              price(currency: $currency)
              quarter {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`);

const offerSchema = z.object({
  id: z.string(),
  harvestYear: z.number(),
  crop: z.object({
    id: z.string(),
    name: z.string(),
  }),
  parameters: z.array(
    z.object({
      id: z.string(),
      value: z.number().or(z.string()),
      title: z.string(),
    }),
  ),
  storage: z.object({
    id: z.string(),
    name: z.string(),
    address: z.string(),
    company: z.object({
      name: z.string(),
    }),
    offer: z.object({
      amount: z.number(),
      totalAmount: z.number(),
    }),
  }),
  price: z.object({
    total: z.number().or(z.undefined()),
    perUnit: z.number().or(z.undefined()),
  }),
});

type Offer = z.infer<typeof offerSchema>;

const offerPricesQuery = ({
  id,
  currency,
  cropId,
}: {
  id: string;
  currency: "CZK" | "EUR";
  cropId: string;
}) =>
  queryOptions({
    queryKey: ["offer", id, currency, cropId],
    queryFn: () =>
      graphqlClient.request(offerPricesQueryDocument, {
        currency: z.enum([Currency.Czk, Currency.Eur]).parse(currency),
        id,
        cropIds: [cropId],
      }),
    select: (data) => {
      const prices = data.offer?.storage?.lastPrices?.edges?.map((edge) => ({
        id: edge?.node?.id,
        value: edge?.node?.price,
        currency,
        quarter: edge?.node?.quarter?.name,
      }));

      const schema = z.array(
        z.object({
          id: z.string(),
          value: z.number(),
          currency: z.string(),
          quarter: z.string(),
        }),
      );

      return schema.parse(prices);
    },
  });

const offerQuery = ({ id }: { id: string }) =>
  queryOptions({
    queryKey: ["offer", id],
    queryFn: () =>
      graphqlClient.request(offerQueryDocument, {
        id,
      }),
    select: (data) => {
      const result = {
        id: data.offer?.id,
        harvestYear: data.offer?.yearHarvested,
        crop: {
          id: data.offer?.crop?.id,
          name: data.offer?.crop?.name,
        },
        storage: {
          id: data.offer?.storage?.id,
          name: data.offer?.storage?.label,
          address: data.offer?.storage?.address,
          company: {
            name: data.offer?.storage?.company?.name,
          },
          offer: {
            harvestYear: data.offer?.yearHarvested,
            amount: data.offer?.amount,
            totalAmount: data.offer?.totalAmount,
          },
        },
        parameters: data.offer?.parameters?.edges
          ?.map((edge) => {
            if (!edge?.node) {
              return;
            }
            if (!("value" in edge.node)) {
              return;
            }

            if (!edge.node.cropParameter) {
              return;
            }
            // FIXME
            // @ts-expect-error value exists, but the types are wrong
            const formattedValue = numberFormatter().format(edge.node.value);
            return {
              value: formattedValue + " " + edge.node.cropParameter.unit,
              id: edge.node.id,
              title: edge.node.cropParameter.title,
            };
          })
          .filter(Boolean),
        price: {
          total: 0,
          perUnit: 0,
        },
      };

      return offerSchema.parse(result);
    },
  });

export { offerQuery, offerPricesQuery };
export type { Offer };
