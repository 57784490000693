import {
  keepPreviousData,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  Company,
  authorizedCountriesQuery,
  companiesQuery,
} from "./companies.api";
import {
  RowData,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Trans, t } from "@lingui/macro";
import { countryCodeToFlag } from "@/common/utils/unicode";
import React, { useId } from "react";
import { Table } from "../components/table/table";
import { Metadata } from "@/common/components/metadata/metadata";
import { Outlet, useSearchParams } from "react-router-dom";
import { Spinner } from "@/common/components/spinner/spinner";
import { Failed, NoData } from "@/common/components/info/info";
import { Filter, SelectFilter } from "../components/table/filter/filter";
import { CompanyToPlanSource, CompanyToPlanType } from "@/generated/api/users";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { AutocompleteString } from "@/types";
import { DateTime } from "luxon";
import { sortBy } from "lodash";
import {
  Pagination,
  itemsPerPage,
} from "@/common/components/pagination/pagination";
import { Spacer } from "@/common/components/spacer/spacer";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterKey?: "search" | "plan" | "plan-source" | "country" | "plan-valid";
    filterVariant?: "text" | "select";
    label: () => string;
  }
}

const path = "companies";
const columnHelper = createColumnHelper<Company>();
const fallbackTable = [] as Company[];

const useCompanyParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planValid = searchParams.get("plan-valid");

  const params = {
    planValid:
      planValid === "true" ? true : planValid === "false" ? false : undefined,
    country: searchParams.get("country") ?? "",
    page: searchParams.get("page") ?? "1",
    plan: (searchParams.get("plan") ?? "") as CompanyToPlanType,
    planSource: (searchParams.get("plan-source") ?? "") as CompanyToPlanSource,
    search: searchParams.get("search") ?? "",
  };

  const handleParamChange = (
    key: AutocompleteString<
      "search" | "plan" | "plan-source" | "country" | "page"
    >,
    value: string,
  ) => {
    setSearchParams((c) => {
      c.set(key, value);
      return c;
    });
  };

  return { params, handleParamChange };
};

const columns = [
  columnHelper.accessor("id", {
    header: () => null,
    cell: () => null,
  }),
  columnHelper.accessor("country", {
    header: () => t`Země`,
    cell: (info) => countryCodeToFlag(info.getValue()),
    meta: {
      label: () => t`Země`,
      filterVariant: "select",
      filterKey: "country",
    },
  }),
  columnHelper.accessor((row) => row.name + " / " + row.number, {
    cell: (info) => {
      const id = info.row.getValue<string>("id");

      return (
        <SearchParamsLink className="text-can-forest-teal" to={"./" + id}>
          {info.getValue()}
        </SearchParamsLink>
      );
    },
    header: () => t`Název / IČO`,
    id: "number",
    meta: {
      label: () => t`Společnost`,
      filterVariant: "text",
      filterKey: "search",
    },
  }),
  columnHelper.accessor("plan.type", {
    id: "planType",
    header: () => t`Forma předplatného`,
    cell: (info) => info.getValue(),
    meta: {
      label: () => t`Forma předplatného`,
      filterKey: "plan",
      filterVariant: "select",
    },
  }),
  columnHelper.accessor("plan.source", {
    id: "planSource",
    header: () => t`Zdroj předplatného`,
    cell: (info) => info.getValue(),
    meta: {
      label: () => t`Zdroj předplatného`,
      filterVariant: "select",
      filterKey: "plan-source",
    },
  }),
  columnHelper.accessor("plan.expiresAt", {
    id: "planExpiresAt",
    header: () => t`Předplatné do`,
    cell: (info) => info.getValue()?.toLocaleString(DateTime.DATE_SHORT),
    meta: {
      label: () => t`Předplatné do`,
      filterVariant: "select",
      filterKey: "plan-valid",
    },
  }),
];

const Companies = () => {
  const titleKey = useId();
  const authorizedCountries = useSuspenseQuery(authorizedCountriesQuery());
  const { params, handleParamChange } = useCompanyParams();
  const companies = useQuery({
    placeholderData: keepPreviousData,
    ...companiesQuery(params),
  });

  // tanstack query `select` doesn't work with tanstack table
  const data = React.useMemo(
    () => ({
      totalSize: companies.data?.totalSize,
      companies: sortBy(
        companies.data?.data.map(
          (company) =>
            ({
              id: company.id,
              number: company.companyNumber,
              country: company.country,
              name: company.name,
              plan: {
                source: company.planSource,
                type: company.planType,
                expiresAt: company.planExpiresAt
                  ? DateTime.fromISO(company.planExpiresAt)
                  : undefined,
              },
            }) satisfies Company,
        ),
        (company) => company.name.toLowerCase(),
      ),
    }),
    [companies.data],
  );

  const table = useReactTable({
    data: data.companies ?? fallbackTable,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    state: {
      columnVisibility: {
        id: false,
        name: false,
      },
    },
  });

  if (companies.status === "pending" && companies.isInitialLoading) {
    return (
      <>
        <Metadata key={titleKey} title={t`Správa společností`} />
        <Spinner className="mt-4" />
      </>
    );
  }

  if (companies.status === "error") {
    return <Failed error={companies.error} />;
  }

  return (
    <>
      <Metadata key={titleKey} title={t`Správa společností`} />
      <Table>
        <Table.Head
          table={table}
          filters={{
            country: (
              <SelectFilter
                onFilterChange={handleParamChange}
                value={params.country}
                values={authorizedCountries.data.map((country) => ({
                  label: countryCodeToFlag(country.id),
                  value: country.id,
                }))}
                column={table.getColumn("country")!}
              />
            ),
            number: (
              <Filter
                onFilterChange={handleParamChange}
                value={params.search}
                column={table.getColumn("number")!}
              />
            ),
            planType: (
              <SelectFilter
                values={[
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  { label: "Basic", value: "BASIC" },
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  { label: "Premium", value: "PREMIUM" },
                ]}
                value={params.plan}
                onFilterChange={handleParamChange}
                column={table.getColumn("planType")!}
              />
            ),
            planSource: (
              <SelectFilter
                values={Object.values(CompanyToPlanSource).map((value) => ({
                  label: value,
                  value,
                }))}
                value={params.planSource}
                onFilterChange={handleParamChange}
                column={table.getColumn("planSource")!}
              />
            ),
            planExpiresAt: (
              <SelectFilter
                values={[
                  { label: t`Zaplacené`, value: "true" },
                  { label: t`Expirované`, value: "false" },
                ]}
                value={params.planValid?.toString() ?? ""}
                onFilterChange={handleParamChange}
                column={table.getColumn("planExpiresAt")!}
              />
            ),
          }}
        />
        {companies.status === "success" && companies.data.data.length > 0 ? (
          <Table.Body table={table} />
        ) : null}
      </Table>
      {companies.data ? (
        <>
          <Spacer className="h-5" />
          <Pagination
            getter={() => parseInt(params.page, 10)}
            setter={(page) => handleParamChange("page", page.toString())}
            pageSize={itemsPerPage.oneHundred}
            total={companies.data.totalSize}
          />
        </>
      ) : null}
      {companies.status === "success" && companies.data?.data.length === 0 ? (
        <div className="mt-4">
          <NoData>
            <Trans>Nebyly nalezeny žádné záznamy</Trans>
          </NoData>
        </div>
      ) : null}
      {companies.fetchStatus === "fetching" && !companies.isInitialLoading ? (
        <Spinner withHint={false} />
      ) : null}
      <Outlet />
    </>
  );
};

export { Companies, path };
