import React from "react";
import { useLocation } from "react-router-dom";
import invariant from "tiny-invariant";

type Screen =
  | "user-profile"
  | "user-company"
  | "user-success"
  | "user-failure"
  | "notifications"
  | undefined;
const initialState: {
  screen: Screen;
  nextAction: Action;
  closeAction: Action;
} = {
  screen: undefined,
  nextAction: { type: "open", screen: "user-profile" },
  closeAction: { type: "toggle" },
};

const Context = React.createContext<
  | {
      state: typeof initialState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

const useSidebar = () => {
  const ctx = React.useContext(Context);
  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "user context is only available within <UserSettingsProvider /> component",
  );

  return ctx;
};

const useAutoclosedSidebar = () => {
  const { dispatch } = useSidebar();
  const location = useLocation();

  React.useEffect(() => {
    dispatch({ type: "toggle" });
  }, [location.key, dispatch]);
};

const Autoclose = ({ children }: React.PropsWithChildren) => {
  useAutoclosedSidebar();

  return <>{children}</>;
};

type Action =
  | { type: "open"; screen: Screen }
  | { type: "toggle" }
  | { type: "noop" }
  | { type: "close" };

const reducer = (
  _state: typeof initialState,
  action: Action,
): typeof initialState => {
  switch (action.type) {
    case "open": {
      switch (action.screen) {
        case "user-success": {
          return {
            screen: action.screen,
            nextAction: { type: "open", screen: "user-profile" },
            closeAction: { type: "toggle" },
          };
        }
        case "user-failure": {
          return {
            screen: action.screen,
            nextAction: { type: "open", screen: "user-profile" },
            closeAction: { type: "toggle" },
          };
        }
        case "user-profile": {
          return {
            screen: action.screen,
            nextAction: { type: "open", screen: "user-company" },
            closeAction: { type: "toggle" },
          };
        }
        case "user-company": {
          return {
            screen: action.screen,
            nextAction: { type: "noop" },
            closeAction: { type: "open", screen: "user-profile" },
          };
        }
        case "notifications": {
          return {
            screen: action.screen,
            nextAction: { type: "noop" },
            closeAction: { type: "toggle" },
          };
        }
      }
      break;
    }
    case "toggle": {
      return {
        screen: undefined,
        nextAction: { type: "open", screen: "user-profile" },
        closeAction: { type: "noop" },
      };
    }
    case "noop":
      break;
    case "close":
      return {
        screen: undefined,
        nextAction: { type: "noop" },
        closeAction: { type: "noop" },
      };
    default: {
      const exhaustiveCheck: never = action;
      throw new Error(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `Unhandled status case: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
  // eslint-disable-next-line lingui/no-unlocalized-strings
  throw new Error("You should have never got here");
};

const SidebarProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { Autoclose, SidebarProvider, useSidebar };
