import { Button } from "@/common/components/button/button";
import Cross from "@icons/cross.svg?react";
import { Spinner } from "@/common/components/spinner/spinner";
import { useQuery } from "@tanstack/react-query";
import { usePurchasePricesParams } from "../purchase-prices";
import { postalCodesQuery } from "../purchase-prices.api";
import { useClickAway } from "@/common/hooks/use-click-away";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import invariant from "tiny-invariant";
import { Trans } from "@lingui/macro";
import { PostalCodeField } from "@/routes/storages/components/storage-form/postal-code-field";

type Fields = {
  postalCode: string;
};

const PostalCodeDialog = () => {
  const { params, closeDialog, setPostalCode } = usePurchasePricesParams();
  const postalCodes = useQuery(postalCodesQuery());
  const clickAwayRef = useClickAway<HTMLDivElement>(closeDialog);
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  const form = useForm<Fields>({
    defaultValues: {
      postalCode: postalCodeObject?.label ?? "",
    },
  });
  const postalCodeValue = form.watch("postalCode");
  const handleSubmit: SubmitHandler<Fields> = () => {
    const postalCode = postalCodeValue.split(" ").at(0);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(postalCode, "postalCode is required");
    setPostalCode(postalCode);
  };

  return (
    <>
      <div className="absolute inset-0 z-10 ml-[72px] bg-[rgba(97,97,97,0.60)] backdrop-blur" />
      {postalCodes.status === "pending" ? <Spinner /> : null}
      {postalCodes.status === "error" ? (
        <p>
          <Trans>Nemůžeme načíst seznam PSČ.</Trans>
        </p>
      ) : null}
      {postalCodes.status === "success" ? (
        <div className="fixed inset-0 z-[100] sm:z-20  sm:m-auto sm:h-[350px] sm:w-[400px] sm:pl-10">
          <div className="sm:relative sm:h-fit sm:max-w-40 sm:self-start">
            <div className="overflow-hidden" ref={clickAwayRef}>
              <FormProvider {...form}>
                <PostalCodeField.Provider>
                  <form
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="flex h-[100dvh] shrink-0 flex-col gap-y-6 overflow-y-auto bg-white px-6 pb-8 sm:fixed sm:m-auto sm:h-fit sm:w-fit sm:max-w-[400px] sm:rounded-2xl"
                  >
                    <button
                      className="mt-6 self-start md:hidden"
                      onClick={closeDialog}
                    >
                      <Cross />
                    </button>
                    <h1 className="text-2xl font-bold text-can-forest-teal md:mt-6 md:text-center">
                      <Trans>Pro výpočet cen vyplňte PSČ</Trans>
                    </h1>
                    <p className="md:text-center">
                      <Trans>
                        Pro výpočet ceny vyplňte do pole níže poštovní směrovací
                        číslo umístění skladu (PSČ).
                      </Trans>
                    </p>
                    <PostalCodeField label={<Trans>Vaše PSČ</Trans>} />
                    <Button className="mt-auto" variant="primary" type="submit">
                      <Trans>Zobrazit ceny</Trans>
                    </Button>
                  </form>
                  <PostalCodeField.Suggestions className="left-6 right-6 top-[300px] w-[calc(100%-theme(spacing.12))] sm:top-[250px] sm:w-[352px]" />
                </PostalCodeField.Provider>
              </FormProvider>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { PostalCodeDialog };
