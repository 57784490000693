import { Helmet } from "react-helmet";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getAbsolutePath } from "../routes.utils";
import {
  Autoclose,
  SidebarProvider,
} from "@/common/providers/sidebar-provider";
import { PWAInstaller } from "@/common/components/pwa-installer/pwa-installer";

const Root = ({ children }: React.PropsWithChildren) => {
  return (
    <SidebarProvider>
      <Autoclose>
        <Helmet>
          {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
          <title>Clever°Analytics&apos;</title>
        </Helmet>
        <PWAInstaller />
        <Outlet />
        {children}
      </Autoclose>
    </SidebarProvider>
  );
};

const NavigateToInitialPage = ({ page }: { page: string }) => {
  const { pathname } = useLocation();

  if (pathname === "/") {
    return <Navigate to={getAbsolutePath(page)} replace />;
  }

  return null;
};

export { Root, NavigateToInitialPage };
