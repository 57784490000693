const WrapMeWith = ({
  children,
  when,
  wrapper,
}: React.PropsWithChildren<{
  when: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}>) => {
  if (!when) {
    return children;
  }

  return wrapper(children);
};

export { WrapMeWith };
