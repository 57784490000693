import { getUser1 } from "@/generated/api/users";
import { queryOptions } from "@tanstack/react-query";

const userQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["user", id],
    queryFn: ({ signal }) => getUser1(id, { signal }),
  });

export { userQuery };
