import React from "react";

const usePrevious = <T>(input: T): T | undefined => {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = input;
  }, [input]);

  return ref.current;
};

export { usePrevious };
