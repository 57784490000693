import { Table as TanstackTable, flexRender } from "@tanstack/react-table";
import { useIsMobile } from "@/common/hooks/use-media-query";

const Table = ({ children }: React.PropsWithChildren) => (
  <table className="w-full table-auto text-xs text-can-midnight-steel sm:text-base md:table">
    {children}
  </table>
);

const Head = <T,>({
  table,
  filters,
}: {
  table: TanstackTable<T>;
  filters?: Record<string, React.ReactNode>;
}) => {
  const isMobile = useIsMobile();

  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} className="md:table-row">
          {headerGroup.headers
            .filter((header) =>
              isMobile ? header.column.getCanFilter() : true,
            )
            .map((header) => (
              <th
                key={header.id}
                className="block px-4 py-2 text-left align-top md:table-cell"
              >
                <div className="flex items-center justify-between md:block">
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </div>
                  {header.column.getCanFilter() && filters
                    ? filters[header.column.id]
                    : null}
                </div>
              </th>
            ))}
        </tr>
      ))}
    </thead>
  );
};

const Body = <T,>({ table }: { table: TanstackTable<T> }) => (
  <tbody className="md:table-row-group">
    {table.getRowModel().rows.map((row) => (
      <tr
        key={row.id}
        className="my-4 block even:bg-can-silver-gray md:mb-0 md:table-row"
      >
        {row.getVisibleCells().map((cell) => (
          <td
            key={cell.id}
            className="flex break-words px-4 py-2 md:table-cell"
          >
            <span className="md:hidden">
              {cell.column.columnDef.meta?.label()}
            </span>
            <span className="ml-auto md:ml-0">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </span>
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

Table.Head = Head;
Table.Body = Body;

export { Table };
