import { TooManyCompaniesError } from "@/api/errors";
import {
  CompanyPutRequest,
  CountryToPopulatorFieldsItem,
  getCompanies,
  getCompany,
  getCountries,
  updateCompanyDetail,
} from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";
import invariant from "tiny-invariant";

const useUpdateCompanyMutation = () =>
  useMutation({
    mutationKey: ["company", "update"],
    mutationFn: ({ id, ...payload }: CompanyPutRequest & { id: number }) =>
      updateCompanyDetail(id, payload),
  });

const companyQuery = () =>
  queryOptions({
    queryKey: ["company-info"],
    queryFn: async ({ signal }) => {
      const [countries, companies] = await Promise.all([
        getCountries({ signal }),
        getCompanies({ signal }),
      ]);

      if (companies.length > 1) {
        throw new TooManyCompaniesError();
      }

      const firstCompany = companies.at(0)?.companyId;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      invariant(firstCompany, "Company has to be defined");
      const company = await getCompany(firstCompany);

      return [countries, company] as const;
    },
    select: ([countries, company]) => {
      const disabledFields =
        countries.find((country) => country.id === company.country)
          ?.populatorFields ?? [];

      return {
        disabledFields: mapPopulatorFields(disabledFields),
        company,
      } as const;
    },
  });

const mapPopulatorFields = (populatorFields: CountryToPopulatorFieldsItem[]) =>
  populatorFields.map((field) => {
    switch (field) {
      case "VAT":
        return "vat";
      case "FORM":
        return "legalForm";
      case "ADDRESS":
        return "address";
    }
  });

export { companyQuery, useUpdateCompanyMutation };
