import Trash from "@icons/trash.svg?url";

const Button = ({
  className = "",
  variant,
  children,
  disabled,
  ...rest
}: React.PropsWithChildren<
  {
    variant: "primary" | "secondary";
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
>) => {
  return (
    <button
      className={`${variants[variant]} ${className} ${disabled ? "animate-can-loading-gradient cursor-wait bg-[length:400%_400%]" : ""}`}
      {...rest}
    >
      {children}
    </button>
  );
};
const variants = {
  primary:
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "rounded-2xl text-center bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua px-4 py-3 font-bold text-white shadow-can-light-box",
  secondary:
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "rounded-2xl bg-white border-can-forest-teal border text-center px-4 py-3 font-bold text-can-forest-teal",
};

// helps keeping the buttons sticky to the bottom
const Container = ({
  children,
  className = "",
}: React.PropsWithChildren<{ className?: string }>) => (
  <div
    className={`sticky bottom-0 -ml-4 -mr-4 flex grow flex-col justify-end gap-y-4 bg-white px-4 pb-8 pt-4 ${className}`}
  >
    {children}
  </div>
);

const Delete = ({
  children,
  ...rest
}: React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>) => {
  return (
    <button
      style={{
        background: `url(${Trash}) left 16px center no-repeat`,
      }}
      className="flex items-center justify-center rounded-2xl border border-can-forest-teal px-4 py-3 font-bold text-can-forest-teal"
      {...rest}
    >
      {children}
    </button>
  );
};

Button.Container = Container;
Button.Delete = Delete;
const primary = variants.primary;
const secondary = variants.secondary;

export { Button, primary, secondary };
