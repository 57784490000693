import { CommodityRatesToCommodity } from "@/generated/api/prices";
import Wheat from "@icons/wheat.svg?react";
import Oil from "@icons/oil.svg?react";
import Rapeseed from "@icons/rapeseed.svg?react";
import { DateTime } from "luxon";
import { createSearchParams } from "react-router-dom";
import { z } from "zod";
import { currencies } from "@/common/hooks/use-currency";
import { Trans, t } from "@lingui/macro";

type Mapping = {
  [key in CommodityRatesToCommodity]: {
    param: Lowercase<key>;
    title: React.ReactNode;
  };
};
const mapping: Mapping = {
  RAPESEED: {
    param: "rapeseed",
    title: (
      <>
        <Rapeseed /> <Trans>Řepka</Trans>
      </>
    ),
  },
  WHEAT: {
    param: "wheat",
    title: (
      <>
        <Wheat /> <Trans>Pšenice</Trans>
      </>
    ),
  },
  CRUDE_OIL: {
    param: "crude_oil",
    title: (
      <>
        <Oil /> <Trans>Ropa</Trans>
      </>
    ),
  },
};
type Params = z.infer<typeof stockExchangeParamsSchema>;

const ranges = [
  "today",
  "last_week",
  "last_month",
  "last_year",
  "all",
] as const;
const rangesSchema = z.enum(ranges);
const stockExchangeParamsSchema = z.object({
  wheat: rangesSchema,
  rapeseed: rangesSchema,
  crude_oil: rangesSchema,
  currencyPair: rangesSchema,
});
const defaultParams = {
  wheat: "last_week",
  rapeseed: "last_week",
  crude_oil: "last_week",
  currencyPair: "last_week",
} satisfies Params;
const initialParams = createSearchParams(defaultParams);
const getFilters = () =>
  [
    {
      label: t`Dnes`,
      value: "today",
      dateFrom: DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
      dateTo: DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
    },
    {
      label: t`Týden`,
      value: "last_week",
      dateFrom: DateTime.now()
        .startOf("day")
        .minus({ week: 1 })
        .toFormat("yyyy-MM-dd"),
      dateTo: DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
    },
    {
      label: t`Měsíc`,
      value: "last_month",
      dateFrom: DateTime.now()
        .startOf("day")
        .minus({ month: 1 })
        .toFormat("yyyy-MM-dd"),
      dateTo: DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
    },
    {
      label: t`Rok`,
      value: "last_year",
      dateFrom: DateTime.now()
        .startOf("day")
        .minus({ year: 1 })
        .toFormat("yyyy-MM-dd"),
      dateTo: DateTime.now().startOf("day").toFormat("yyyy-MM-dd"),
    },
    {
      label: t`Vše`,
      value: "all",
      dateFrom: "",
      dateTo: "",
    },
  ] as const satisfies Filter[];

type Filter = {
  label: string;
  value: Range;
  dateFrom: string;
  dateTo: string;
};
type Range = (typeof ranges)[number];

export {
  getFilters,
  initialParams,
  stockExchangeParamsSchema,
  currencies,
  mapping,
};
export type { Filter };
export type { Range };
