import React from "react";
import { CropIcon } from "@/common/components/crop-icon/crop-icon";
import AngleRight from "@icons/angle-right.svg?react";

type Crop = {
  id: string;
  name: string;
};

const CropList = ({
  children,
  onClick,
  crops = [],
}: React.PropsWithChildren<{
  onClick: (crop: Crop) => void;
  crops?: Array<Crop>;
}>) => {
  return (
    <div className="flex grow flex-col gap-y-4">
      {children}
      <ul className="mb-4 flex flex-col gap-y-6">
        {crops.map((crop) => (
          <li key={crop.id}>
            <button
              onClick={() => onClick(crop)}
              className="flex w-full items-center gap-2"
            >
              <CropIcon
                className="flex-shrink-0 peer-checked:[&_path]:fill-can-forest-teal"
                cropName={crop.name}
              />
              <span>{crop.name}</span>
              <AngleRight className="ml-auto" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { CropList };
export type { Crop };
