import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// eslint-disable-next-line lingui/no-unlocalized-strings
const archiveOfferDocument = graphql(`
  mutation ArchiveOffer($id: ID!) {
    archiveOffer(id: $id) {
      id
    }
  }
`);

const useArchiveOffer = () => {
  const client = useQueryClient();
  return useMutation({
    onSuccess: () => {
      void client.invalidateQueries({
        queryKey: ["user"],
      });
    },
    mutationFn: (id: string) =>
      graphqlClient.request(archiveOfferDocument, {
        id,
      }),
  });
};

export { useArchiveOffer };
