import { DateTime } from "luxon";

const newestFirst = (
  a: { timeCreated: DateTime },
  b: { timeCreated: DateTime },
) => {
  if (a.timeCreated < b.timeCreated) {
    return 1;
  }

  if (a.timeCreated > b.timeCreated) {
    return -1;
  }

  return 0;
};

export { newestFirst };
