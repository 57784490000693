import { logger } from "@/common/services/logger";
import { t } from "@lingui/macro";
import { z } from "zod";

const graphqlErrorSchema = z.object({
  response: z.object({
    errors: z.array(
      z.object({
        extensions: z.object({
          validation: z.record(
            z.string(),
            z.array(
              z.object({
                message: z.string(),
              }),
            ),
          ),
        }),
      }),
    ),
  }),
});

const handleFormError = (e: Error) => {
  const errors: { field: string; error: string[] }[] = [];
  const graphqlError = graphqlErrorSchema.safeParse(e);

  if (graphqlError.success) {
    graphqlError.data.response.errors.forEach((error) => {
      const validation = error.extensions.validation;
      Object.keys(validation).forEach((key) => {
        errors.push({
          field: key,
          error: validation[key].map((x) => x.message),
        });
      });
    });
  } else {
    logger.error(graphqlError.error);
    errors.push({
      field: "root",
      error: [t`Nastala neočekávaná chyba`],
    });
  }

  return errors;
};

export { handleFormError };
