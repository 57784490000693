import { Auth0Provider } from "@auth0/auth0-react";
import { getEnvironmentVariable } from "../services/env";

const AuthProvider = ({ children }: React.PropsWithChildren) => (
  <Auth0Provider
    cacheLocation="localstorage"
    clientId={getEnvironmentVariable("AUTH0_CLIENT_ID")}
    domain={getEnvironmentVariable("AUTH0_DOMAIN")}
    authorizationParams={{
      audience: getEnvironmentVariable("AUTH0_AUDIENCE"),
      redirect_uri: `${window.location.origin}/callback`,
    }}
    useRefreshTokens
  >
    {children}
  </Auth0Provider>
);

export { AuthProvider };
