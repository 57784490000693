import { MergeSearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { GetReportsSortOrder } from "@/generated/api/news";
import SortAlt from "@icons/sort-alt.svg?react";
import { t } from "@lingui/macro";
import { useSearchParams } from "react-router-dom";

const params = {
  sortOrder: "sort-order",
} as const;

const SortOrder = () => {
  const [searchParams] = useSearchParams();
  const sortOrder = searchParams.get(params.sortOrder);
  const nextSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";

  return (
    <MergeSearchParamsLink
      to={`?${params.sortOrder}=${nextSortOrder}`}
      className="flex items-center gap-2 self-center font-can-inter"
    >
      {sortOrder === "DESC" ? t`Nejnovější` : t`Nejstarší`} <SortAlt />
    </MergeSearchParamsLink>
  );
};

const initialSortOrder = {
  [params.sortOrder]: GetReportsSortOrder.DESC,
};

export { SortOrder, initialSortOrder };
