import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import AngleLeft from "@icons/angle-left.svg?react";
import { Metadata } from "@/common/components/metadata/metadata";
import { Spacer } from "@/common/components/spacer/spacer";
import {
  Link,
  Outlet,
  generatePath,
  resolvePath,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { z } from "zod";
import { entitySchema } from "@/common/schema/entity";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import { FormProvider, useForm } from "react-hook-form";
import { Button, primary, secondary } from "@/common/components/button/button";
import { currencyFormatter } from "@/common/services/formatter";
import { useCurrency } from "@/common/hooks/use-currency";
import { useAddContractMutation } from "./trades.api";
import invariant from "tiny-invariant";
import { handleFormError } from "../shared/api/error-handler";
import { getAbsolutePath } from "../routes.utils";
import { path as transactionsAndOrdersPath } from "../transactions-and-orders/transactions-and-orders";
import { useLingui } from "@lingui/react";
import { Trans, msg, t } from "@lingui/macro";
import { isFinite } from "lodash";
import { useContractSignGuard } from "@/common/acl/statutory-guard/contract-sign-guard";
import { InputField } from "@/common/components/form/input-field/input-field";

type Fields = {
  customTransport: boolean;
  customTransportNote?: string;
  note: string;
  amount: number;
};

const path = "sell";
const TradesDetailSell = () => {
  const { _ } = useLingui();
  const [, currency] = useCurrency();
  const { offerId } = useParams();
  invariant(offerId);
  const context = useTradesDetailSellContext();
  const form = useForm<Fields>();
  const hasCustomTransport = form.watch("customTransport");
  const addContract = useAddContractMutation();
  const navigate = useNavigate();
  const amount = form.watch("amount");
  const totalPrice = isFinite(amount)
    ? amount * context.marketPrice.price
    : undefined;

  const handleSubmit = ({ customTransportNote, ...data }: Fields) => {
    addContract.mutate(
      {
        ...data,
        marketPriceId: context.marketPrice.id,
        offerId,
        currency,
        transportNote: customTransportNote,
      },
      {
        onSuccess: (data) => {
          navigate("../success/contract/" + data.addContract?.id);
        },
        onError: (e) => {
          const errors = handleFormError(e);

          errors.forEach((error) => {
            if (error.field.includes("contract")) {
              form.setError("amount", { message: error.error.join("\n") });
            }
          });
        },
      },
    );
  };
  const guardedSubmit = useContractSignGuard({ action: handleSubmit });

  if (!hasCustomTransport) {
    form.setValue("customTransportNote", undefined);
  }

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink to=".." replace>
            <AngleLeft />
          </SearchParamsLink>
        }
      >
        <Metadata title={_(msg`Prodat komoditu`)} />
        <Spacer className="h-6" />
        <h1 className="text-2xl font-black text-can-forest-teal">
          <Trans>Prodat komoditu</Trans>
        </h1>
        <Spacer className="h-6" />
        <OfferPropertiesList
          properties={[
            {
              label: <Trans>Komodita</Trans>,
              value: context.crop.name,
            },
            {
              label: <Trans>Farma</Trans>,
              value: context.company.name,
            },
            {
              label: <Trans>Sklad</Trans>,
              value: context.storage.name,
            },
            {
              label: <Trans>Celková cena</Trans>,
              value: totalPrice
                ? currencyFormatter(currency).format(totalPrice)
                : "-",
            },
            {
              label: <Trans>Obchodník</Trans>,
              value: "TOP EKOS",
            },
          ]}
        />
        <Spacer className="h-6" />
        <FormProvider {...form}>
          <form id="sell-form" onSubmit={form.handleSubmit(guardedSubmit)}>
            <fieldset disabled={addContract.status === "pending"}>
              <InputField<Fields>
                options={{
                  min: {
                    value: 1,
                    message: t`Množství musí být vyšší než 1 tuna`,
                  },
                  max: {
                    value: context.offer.amount,
                    message: t`Množství je vyšší než dostupné množství ve skladu`,
                  },
                  required: t`Vyplňte prosím množství`,
                  valueAsNumber: true,
                }}
                type="number"
                step="0.1"
                autoComplete="off"
                name="amount"
                label={<Trans>Množství v tunách</Trans>}
              />
              <Spacer className="h-6" />
              <label
                className="relative flex h-6 justify-between"
                htmlFor="custom-transport"
              >
                <Trans>Vlastní doprava</Trans>
                <input
                  {...form.register("customTransport")}
                  type="checkbox"
                  role="switch"
                />
              </label>
              {hasCustomTransport ? (
                <>
                  <Spacer className="h-6" />
                  <label className="" htmlFor="custom-transport-note">
                    <Trans>Poznámka k dopravě</Trans>
                    <textarea
                      className="mt-2 w-full rounded-lg"
                      {...form.register("customTransportNote")}
                    />
                  </label>
                </>
              ) : null}
              <Spacer className="h-6" />
              <label htmlFor="note">
                <Trans>Poznámka</Trans>
                <textarea
                  className="mt-2 w-full rounded-lg"
                  {...form.register("note")}
                  id="note"
                />
              </label>
            </fieldset>
          </form>
        </FormProvider>
        <Button.Container>
          <SearchParamsLink to="order" className={secondary}>
            <Trans>Nastavit příkaz k prodeji</Trans>
          </SearchParamsLink>
          <Button
            disabled={addContract.status === "pending"}
            form="sell-form"
            type="submit"
            variant="primary"
          >
            <Trans>Prodat za</Trans>{" "}
            {currencyFormatter(currency).format(context.marketPrice.price)}
          </Button>
        </Button.Container>
      </FullscreenModalLayout>
      <Outlet context={context} />
    </>
  );
};

const TransactionLink = () => {
  const { contractId } = useParams();
  invariant(contractId);

  return (
    <Link
      to={resolvePath(
        generatePath("transactions/:contractId", { contractId }),
        getAbsolutePath(transactionsAndOrdersPath),
      )}
      replace
      className={primary}
    >
      <Trans>Přejít na detail příkazu</Trans>
    </Link>
  );
};

const contextSchema = z.object({
  crop: entitySchema,
  company: entitySchema,
  storage: entitySchema,
  offer: z.object({
    amount: z.number(),
  }),
  marketPrice: z.object({
    id: z.string(),
    price: z.number(),
  }),
});

const useTradesDetailSellContext = () => {
  const context = useOutletContext();

  return contextSchema.parse(context);
};

export { TradesDetailSell, path, contextSchema, TransactionLink };
