import newsPlaceholderSvg from "@/assets/news-placeholder.svg";
import { Spacer } from "@/common/components/spacer/spacer";
import { nullIf } from "@/common/utils";
import { DateTime } from "luxon";
import React from "react";
import { ReportsQueryResult } from "@/routes/reports/reports.api";

type Props =
  | {
      skeleton: true;
      className?: string;
    }
  | ({
      skeleton?: false;
      className?: string;
    } & ReportsQueryResult);

const skeletonProps = {
  dateFrom: "",
  dateTo: "",
  headline: "",
  summary: "",
  skeleton: true,
  image: undefined,
  className: "",
};

const PreviewCard = (props: Props) => {
  const { headline, summary, dateFrom, dateTo, image, skeleton, className } =
    props.skeleton ? skeletonProps : props;
  const _dateFrom = DateTime.fromISO(dateFrom).toJSDate();
  const _dateTo = DateTime.fromISO(dateTo).toJSDate();
  const dateRangeFormatter = new Intl.DateTimeFormat();
  const _image = nullIf(image?.url, "");
  const { ref: imageRef, status: imageStatus } = useFailedImage();
  const imageLoaded = _image && imageStatus !== "error";

  return (
    <article
      role={skeleton ? "status" : undefined}
      className={`flex flex-col items-center justify-center rounded-can-2xl p-6 pt-0 shadow-can-light-box ${className} ${
        skeleton ? "animate-pulse grayscale" : ""
      }`}
    >
      <Spacer className={`${imageLoaded ? "h-6" : "h-11"}`} />
      <div className="flex h-full w-full justify-center">
        <img
          ref={imageRef}
          src={_image ?? newsPlaceholderSvg}
          className={`${
            imageLoaded
              ? "h-[205px] w-full rounded-lg border border-can-silver-cloud"
              : "h-40 w-40"
          } object-cover`}
        />
      </div>
      <Spacer
        className={imageLoaded ? "h-4" : "h-[calc(theme(spacing.11)-3px)]"}
      />
      <div
        className={
          skeleton
            ? "flex self-stretch rounded bg-gray-200"
            : "flex gap-2 self-start"
        }
      >
        <TimeSpan>
          {skeleton ? (
            <>&nbsp;</>
          ) : (
            dateRangeFormatter.formatRange(_dateFrom, _dateTo)
          )}
        </TimeSpan>
      </div>
      <Spacer className="h-2" />
      <Headline>{headline}</Headline>
      <Spacer className="h-4" />
      <Summary>{summary}</Summary>
    </article>
  );
};

const Summary = ({ children }: React.PropsWithChildren) => {
  return (
    <p className="line-clamp-2 min-h-12 self-start text-can-slate-blue-gray">
      {children}
    </p>
  );
};

const Headline = ({ children }: React.PropsWithChildren) => {
  return (
    <h2 className="line-clamp-2 min-h-14 self-start text-xl font-black text-can-midnight-steel">
      {children}
    </h2>
  );
};

const TimeSpan = ({ children }: React.PropsWithChildren) => {
  return <span className="text-can-slate-blue-gray">{children}</span>;
};

// @ts-expect-error unused component
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Tag = ({ children }: React.PropsWithChildren) => {
  return (
    <span className="self-start rounded bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua px-1.5 py-1 text-xs text-white">
      {children}
    </span>
  );
};

const useFailedImage = () => {
  const ref = React.useRef<HTMLImageElement>(null);
  const [status, setStatus] = React.useState<"pending" | "error">("pending");

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener("error", function () {
      this.src = newsPlaceholderSvg;
      setStatus("error");
    });
  }, []);

  return { ref, status };
};

export { PreviewCard };
