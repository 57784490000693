const EditorErrorMessage = ({ message }: { message: string }) => {
  return (
    <span
      role="alert"
      className="self-start rounded bg-red-100 px-2 text-can-midnight-steel"
    >
      {message}
    </span>
  );
};

export { EditorErrorMessage };
