import { getEnvironmentVariable } from "@/common/services/env";
import { Helmet } from "react-helmet";

const Metadata = ({ title }: { title?: string }) => {
  const appMode = getEnvironmentVariable("APP_MODE");
  const titleAppMode =
    appMode === "production" ? "" : `[${appMode.toUpperCase()}] `;

  return (
    <Helmet>
      <title>
        {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
        {titleAppMode}
        Clever˚Analytics&apos;
        {title ? ` | ${title}` : undefined}
      </title>
    </Helmet>
  );
};

export { Metadata };
