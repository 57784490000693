const ProgressBar = ({ max, value }: { max: number; value: number }) => (
  <progress
    className="h-2 w-full rounded-2xl [&[value]::-moz-progress-bar]:bg-can-gradient-to-r-80 [&[value]::-moz-progress-bar]:from-can-tranquil-azure [&[value]::-moz-progress-bar]:to-can-mystic-aqua [&[value]::-webkit-progress-bar]:rounded-2xl [&[value]::-webkit-progress-bar]:bg-can-gradient-to-r-80 [&[value]::-webkit-progress-value]:rounded-2xl [&[value]::-webkit-progress-value]:bg-can-gradient-to-r-80 [&[value]::-webkit-progress-value]:from-can-tranquil-azure [&[value]::-webkit-progress-value]:to-can-mystic-aqua"
    value={value.toString()}
    max={max.toString()}
  />
);

const ThreeStepProgressBar = ({
  values: { orderAmount, totalAmount, freeAmount },
}: React.PropsWithChildren<{
  values: {
    orderAmount: number;
    totalAmount: number;
    freeAmount: number;
  };
}>) => {
  const contractAmount = totalAmount - orderAmount - freeAmount;

  return (
    <div className="flex flex-nowrap [&>:first-child]:rounded-l-[4px] [&>:last-child]:rounded-r-[4px]">
      {freeAmount > 0 ? (
        <hr
          className="h-2 bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua"
          style={{ width: `${(freeAmount / totalAmount) * 100}%` }}
        />
      ) : null}
      {orderAmount > 0 ? (
        <hr
          className="h-2 bg-can-gradient-to-r-80 from-[#FFC684] to-[#FF914D]"
          style={{ width: `${(orderAmount / totalAmount) * 100}%` }}
        />
      ) : null}
      {contractAmount > 0 ? (
        <hr
          className="h-2 bg-can-silver-gray"
          style={{ width: `${(contractAmount / totalAmount) * 100}%` }}
        />
      ) : null}
    </div>
  );
};

export { ProgressBar, ThreeStepProgressBar };
