import { Dialog } from "@/common/components/dialog/dialog";
import { Failed } from "@/common/components/info/info";
import { Spinner } from "@/common/components/spinner/spinner";
import { useClickAway } from "@/common/hooks/use-click-away";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate, useSearchParams } from "react-router-dom";

const Loader = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clickAway = useClickAway<HTMLDivElement>(() =>
    navigate({
      pathname: "..",
      search: searchParams.toString(),
    }),
  );

  return (
    <Dialog>
      <div
        ref={clickAway}
        className="fixed inset-0 m-auto flex flex-col gap-y-4 overflow-auto bg-white p-4 shadow-can-light-box sm:h-fit sm:max-h-[95dvh] sm:w-[800px] sm:max-w-[90vw] sm:rounded-lg"
      >
        <ErrorBoundary fallbackRender={({ error }) => <Failed error={error} />}>
          <Suspense fallback={<Spinner className="m-auto" />}>
            {children}
          </Suspense>
        </ErrorBoundary>
      </div>
    </Dialog>
  );
};

export { Loader };
