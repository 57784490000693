import { getAccessTokenSilently } from "../common/services/auth";

class Token {
  private token?: string;

  public async read({ forced }: { forced: boolean }) {
    if (forced) {
      this.token = await getAccessTokenSilently({ cacheMode: "off" });
      return this.token;
    }

    if (this.token) {
      return this.token;
    }

    this.token = await getAccessTokenSilently();

    return this.token;
  }
}

const token = new Token();

export { Token, token };
