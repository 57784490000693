import { _delete, deleteUser, reset } from "@/generated/api/users";
import { useMutation } from "@tanstack/react-query";

const useDeleteCompanyRelationMutation = () => {
  return useMutation({
    mutationKey: ["company"],
    mutationFn: ({ userId }: { userId: number }) => deleteUser(userId),
  });
};

const useResetUserMutation = () => {
  return useMutation({
    mutationFn: ({ ssoUserId }: { ssoUserId: string }) =>
      reset(encodeURIComponent(ssoUserId)),
  });
};

const useDeleteUserMutation = () => {
  return useMutation({
    mutationKey: ["user"],
    mutationFn: ({ ssoUserId }: { ssoUserId: string }) =>
      _delete(encodeURIComponent(ssoUserId)),
  });
};

export {
  useDeleteCompanyRelationMutation,
  useDeleteUserMutation,
  useResetUserMutation,
};
