import { setUserRole } from "@/generated/api/users";
import { useMutation } from "@tanstack/react-query";

const useUserRoleMutation = () =>
  useMutation({
    mutationFn: ({
      userCompanyRelationId,
      role,
    }: {
      userCompanyRelationId: number;
      role: "USER" | "STATUTORY";
    }) => setUserRole(userCompanyRelationId, role),
  });

export { useUserRoleMutation };
