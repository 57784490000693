import { DateTime } from "luxon";
import { z } from "zod";

const quarterYearSchema = z.string().regex(/Q\d{1} \d{2}/);
const quarterSchema = quarterYearSchema
  .transform((v) => v.split(" ").at(0))
  .pipe(z.string());
const yearSchema = quarterYearSchema
  .transform((v) => v.split(" ").at(1))
  .pipe(z.string())
  .transform((v) => parseInt(v, 10))
  .pipe(z.number());
const quarterTransform = (v: string) => parseInt(v.replace("Q", ""), 10);

const getHarvestYearFromQuarter = (quarterWithYear: string) => {
  const thisYear = getThisYear();
  const quarter = quarterSchema.parse(quarterWithYear);
  let year = yearSchema.parse(quarterWithYear);

  if (quarter === "Q1" || quarter === "Q2") {
    year -= 1;
  }

  return parseInt(thisYear.toString().slice(0, 2) + year.toString(), 10);
};

const getThisYear = () => parseInt(DateTime.now().toFormat("yyyy"), 10);

const getHarvestYears = () =>
  Array.from({ length: 3 }).map((_, i) => getThisYear() + i);

const isNextQuarter = (current: string, next: string) => {
  const currentQuarter = quarterSchema
    .transform(quarterTransform)
    .parse(current);
  const currentYear = yearSchema.parse(current);
  const nextQuarter = quarterSchema.transform(quarterTransform).parse(next);
  const nextYear = yearSchema.parse(next);
  const sameYear = currentYear === nextYear;
  const quarterDeltaOne = nextQuarter - currentQuarter === 1;
  const yearDeltaOne = nextYear - currentYear === 1;
  const lastAndFirstQuarter = currentQuarter === 4 && nextQuarter === 1;

  return (sameYear && quarterDeltaOne) || (lastAndFirstQuarter && yearDeltaOne);
};

const getNextQuarter = (current: string) => {
  const currentQuarter = quarterSchema
    .transform(quarterTransform)
    .parse(current);
  const currentYear = yearSchema.parse(current);

  let nextQuarter;
  let nextYear = currentYear;

  if (currentQuarter === 4) {
    nextQuarter = 1;
    nextYear = currentYear + 1;
  } else {
    nextQuarter = currentQuarter + 1;
  }

  return `Q${nextQuarter} ${nextYear.toString()}`;
};

const getSlashedQuarterName = (quarterName: string) => {
  const validQuarterName = z
    .string()
    .regex(/Q[1,2,3,4] \d{2}/)
    .parse(quarterName);

  return validQuarterName.replace(" ", "/");
};

export {
  getHarvestYearFromQuarter,
  getHarvestYears,
  getSlashedQuarterName,
  getThisYear,
  isNextQuarter,
  getNextQuarter,
};
