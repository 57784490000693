import { FlagProvider, IConfig } from "@unleash/proxy-client-react";
import { getEnvironmentVariable } from "../services/env";

const config: IConfig = {
  url: "https://fe.unleash.can-nightly.dev.cleverfarm.cz/proxy",
  environment: "nightly",
  clientKey: "someSecret",
  refreshInterval: 15,
  appName: "nightly",
};

const ToggleProvider = ({
  children,
  userId,
}: React.PropsWithChildren<{ userId: string }>) => {
  if (
    getEnvironmentVariable("APP_MODE") === "nightly" ||
    getEnvironmentVariable("APP_MODE") === "local"
  ) {
    return (
      <FlagProvider config={{ ...config, context: { userId } }}>
        {children}
      </FlagProvider>
    );
  }
  return <>{children}</>;
};

export { ToggleProvider };
