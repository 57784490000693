import { countryCodeToFlag } from "@/common/utils/unicode";
import {
  CompanySimpleUserToApprovalState,
  GetUsers1ApprovalState,
  GetUsers1SortCol,
  getUsers1,
  setUserState,
} from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { DateTime } from "luxon";

const pendingUsersQuery = ({
  page,
  search,
}: {
  page: string;
  search: string;
}) =>
  queryOptions({
    queryKey: ["get-users", search, page],
    queryFn: async ({ signal }) => {
      const users = await getUsers1(
        {
          approvalState: GetUsers1ApprovalState.WAITING,
          page,
          search,
          sortCol: GetUsers1SortCol.EMAIL,
        },
        { signal },
      );
      return {
        totalSize: users.totalSize,
        users: users.data.map(
          ({
            createdAt,
            companyName,
            companyNumber,
            companyId,
            approvalState,
            companyCountry,
            userEmail,
            ...user
          }) => ({
            ...user,
            approvalStatus: approvalState,
            createdAt: DateTime.fromFormat(createdAt, "yyyy-MM-dd"),
            email: userEmail,
            company: {
              country: companyCountry ? countryCodeToFlag(companyCountry) : "",
              name: companyName,
              number: companyNumber,
              id: companyId,
            },
          }),
        ),
      };
    },
    staleTime: Infinity,
  });

const useUserStateMutation = () =>
  useMutation({
    mutationKey: ["set-user-state"],
    mutationFn: ({
      userCompanyRelationId,
      state,
    }: {
      userCompanyRelationId: number;
      state: CompanySimpleUserToApprovalState;
    }) => setUserState(userCompanyRelationId, state),
  });

export { pendingUsersQuery, useUserStateMutation };
