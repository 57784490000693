import React from "react";

const useLocalStorage = <T>(key: string, initialValue?: T) => {
  const [state, setState] = React.useState<T>(() => {
    const current = getLocalStorageItem(key);

    if (current) {
      try {
        return JSON.parse(current);
      } catch {
        removeLocalStorageItem(key);
      }
    }

    return initialValue;
  });

  React.useEffect(() => {
    if (state === null || state === undefined) {
      removeLocalStorageItem(key);
    } else {
      setLocalStorageItem(key, state);
    }
  }, [key, state]);

  return [state, setState] as const;
};

const setLocalStorageItem = (key: string, value: unknown) => {
  const stringified = JSON.stringify(value);
  window.localStorage.setItem(key, stringified);
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
};

const getLocalStorageItem = (key: string) => window.localStorage.getItem(key);

export { useLocalStorage };
