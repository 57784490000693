import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@/common/services/logger";
import { CommitHash } from "@/common/components/commit-hash/commit-hash";
import { AuthProvider } from "@/common/providers/auth-provider";
import { App } from "./routes/app";
import { getEnvironmentVariable } from "./common/services/env";
import ReactGA from "react-ga4";

/**
 * TODO this doesn't belong here, but loaded from a separate file, it leads to a white screen on mobile safari
 * when accessing localhost via public IP address.
 */
const GoogleAnalyticsProvider = () => {
  const googleAnalyticsId = getEnvironmentVariable("GOOGLE_ANALYTICS_ID");
  const appMode = getEnvironmentVariable("APP_MODE");

  if (appMode === "local") {
    return;
  }

  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId);
  }

  return null;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <App />
      <GoogleAnalyticsProvider />
      <CommitHash />
    </AuthProvider>
  </React.StrictMode>,
);
