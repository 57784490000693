import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { cropAggregatedPricesQuery } from "../../trades.api";
import { QuickFilters } from "@/common/components/quick-filters/quick-filters";
import {
  Filter,
  getAvailableFilters,
  getFilterByDateRange,
  getFilterParams,
  getURLParams,
} from "@/common/components/quick-filters/quick-filters.utils";
import React from "react";
import { Params, useTradeDetailParams } from "../../trades.detail";
import { Failed } from "@/common/components/info/info";
import { Spinner } from "@/common/components/spinner/spinner";
import { useCurrency } from "@/common/hooks/use-currency";
import { DateTime } from "luxon";
import invariant from "tiny-invariant";
import { Chart } from "@/common/components/chart/chart";
import { getSlashedQuarterName } from "@/common/utils/trading";

const PriceChart = ({
  quarterName,
  cropId,
  storageId,
}: {
  quarterName: string;
  cropId: string;
  storageId: string;
}) => {
  const slashedQuarterName = getSlashedQuarterName(quarterName);
  const [, currency] = useCurrency();
  const dateFilters = getAvailableFilters();
  const searchParams = useTradeDetailParams() as Params;
  const onDateFilterClick = useFilterClickHandler(dateFilters);
  const currentDateFilter = getFilterByDateRange(
    dateFilters,
    getFilterParams(searchParams),
  );
  invariant(currentDateFilter?.dateFrom);
  invariant(currentDateFilter?.dateTo);

  const prices = useQuery({
    ...cropAggregatedPricesQuery({
      storageId,
      cropId,
      from: DateTime.fromISO(currentDateFilter?.dateFrom),
      to: DateTime.fromISO(currentDateFilter?.dateTo),
      currency,
    }),
  });

  return (
    <div className="flex flex-col gap-y-8">
      {prices.status === "error" ? <Failed error={prices.error} /> : null}
      {prices.status === "pending" ? (
        <div className="self-center">
          <Spinner />
        </div>
      ) : null}
      {prices.status === "success" ? (
        <>
          <QuickFilters
            onClick={onDateFilterClick}
            activeFilter={currentDateFilter}
            filters={dateFilters}
          />
          <Chart
            data={{
              current: prices.data.filter(
                (d) => d.quarter.name === slashedQuarterName,
              ),
            }}
            currency={currency}
          />
        </>
      ) : null}
    </div>
  );
};

const useFilterClickHandler = (filters: Filter[]) => {
  const [, setSearchParams] = useSearchParams();

  return React.useCallback(
    (index: number) => {
      const urlParams = getURLParams(filters[index]);
      setSearchParams((cur) => {
        Object.entries(urlParams).forEach(([key, value]) =>
          cur.set(key, value),
        );
        return cur;
      });
    },
    [filters, setSearchParams],
  );
};

export { PriceChart };
