import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// eslint-disable-next-line lingui/no-unlocalized-strings
const addStorageDocument = graphql(`
  mutation AddStorage($storage: StorageInput!) {
    addStorage(storage: $storage) {
      id
      label
    }
  }
`);

const useAddStorage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
    mutationFn: async ({
      address,
      companyId,
      name,
      zip,
    }: {
      address: string;
      companyId: string;
      name: string;
      zip: string;
    }) =>
      await graphqlClient.request(addStorageDocument, {
        storage: {
          company: companyId,
          address,
          label: name,
          // TODO handle currency
          currency: Currency.Czk,
          zip,
        },
      }),
  });
};

export { useAddStorage };
