import {
  Filter,
  getURLParams,
  resetURLParams,
} from "@/common/components/quick-filters/quick-filters.utils";
import React from "react";
import { useSearchParams } from "react-router-dom";

const useFilterClickHandler = (filters: Filter[], activeFilter?: Filter) => {
  const [, setSearchParams] = useSearchParams();

  return React.useCallback(
    (index: number) => {
      const filter = filters[index];
      const urlParams = getURLParams(filters[index]);
      const shouldReset = filter.label === activeFilter?.label;
      setSearchParams((cur) => {
        if (shouldReset) {
          resetURLParams(cur);
        } else {
          Object.entries(urlParams).forEach(([key, value]) =>
            cur.set(key, value),
          );
        }
        return cur;
      });
    },
    [activeFilter?.label, filters, setSearchParams],
  );
};

export { useFilterClickHandler };
