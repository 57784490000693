import React from "react";

const useClickAway = <T extends HTMLElement>(
  callback: (e: MouseEvent | TouchEvent | KeyboardEvent) => void,
) => {
  const ref = React.useRef<T>(null);
  const refCallback = React.useRef(callback);

  React.useEffect(() => {
    const handler = (e: MouseEvent | TouchEvent | KeyboardEvent) => {
      const element = ref.current;

      if (element && !element.contains(e.target as Node)) {
        refCallback.current(e);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        handler(e);
      }
    });

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
      document.removeEventListener("keyup", handler);
    };
  }, []);

  return ref;
};

export { useClickAway };
