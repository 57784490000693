import { Args, newsInstance } from "./client";

const imageUpload = <T>(args: Args) => {
  return newsInstance<T>({
    url: args.url,
    method: args.method,
    data: args.data,
  });
};

export { imageUpload };
