import React from "react";

const className = "overflow-hidden";

const useScrollbarToggle = (on: boolean | undefined = false) => {
  React.useEffect(() => {
    if (on) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }

    return () => {
      document.body.classList.remove(className);
    };
  }, [on]);
};

const useLazyScrollbarToggle = () => {
  const toggle = React.useCallback((on: boolean | undefined = false) => {
    if (on) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }

    return () => {
      document.body.classList.remove(className);
    };
  }, []);

  return toggle;
};

export { useScrollbarToggle, useLazyScrollbarToggle };
