import { Navigate, createBrowserRouter } from "react-router-dom";
import { path as stockExchangePath } from "./stock-exchange/stock-exchange";
import { NavigateToInitialPage, Root } from "./root/root";
import { ErrorPage } from "@/common/components/error-page/error-page";
import { Layout } from "./layout";
import { Callback, path as callbackPath } from "./callback/callback";
import * as PurchasePrices from "./purchase-prices/purchase-prices";
import { Anonymous } from "./anonymous/anonymous";
import { Logout } from "./logout/logout";
import * as IncompleteUser from "./incomplete-user/incomplete-user";
import { Flags } from "@/common/services/feature-flag";
import { routes as basicRoutes } from "./routes.basic";
import { routes as tradingRoutes } from "./routes.trading";
import { routes as adminRoutes } from "./routes.admin";
import { BlockedUser } from "./blocked-user/blocked-user";

const createIncompleteUserRouter = () =>
  createBrowserRouter([
    {
      path: IncompleteUser.path,
      element: <IncompleteUser.IncompleteUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <Navigate to={IncompleteUser.path} />,
    },
  ]);

const createBlockedUserRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <BlockedUser />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ]);

const createAnonymousRouter = () =>
  createBrowserRouter([
    {
      path: "*",
      element: <Anonymous />,
    },
    {
      path: callbackPath,
      element: <Callback />,
    },
  ]);

const getRoutes = (flags: Flags) => [
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/",
    element: (
      <Root>
        <NavigateToInitialPage
          page={flags.get("trading") ? PurchasePrices.path : stockExchangePath}
        />
      </Root>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Callback />,
        path: callbackPath,
      },
      {
        element: <Layout />,
        children: [
          ...(flags.get("trading") ? tradingRoutes : []),
          ...basicRoutes(),
        ],
      },
    ],
  },
  ...adminRoutes(),
];

const createRouter = (flags: Flags) => createBrowserRouter(getRoutes(flags));

export {
  createRouter,
  createAnonymousRouter,
  createIncompleteUserRouter,
  createBlockedUserRouter,
  getRoutes,
};
