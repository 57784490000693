import { ProductFruits } from "react-product-fruits";
import { getEnvironmentVariable } from "../services/env";

type Props = {
  language: string;
  user: {
    email: string;
    language: string;
    username: string;
    companyCountryCode?: string;
    phoneCountryCode?: string;
  };
};

const ProductFruitsProvider = (props: Props) => {
  return (
    <ProductFruits
      workspaceCode={getEnvironmentVariable("PRODUCT_FRUITS_WORKSPACE_CODE")}
      language={props.language}
      user={{
        username: props.user.username,
        email: props.user.email,
        props: {
          language: props.user.language,
          companyCountryCode: props.user.companyCountryCode ?? "",
          phoneCountryCode: props.user.phoneCountryCode ?? "",
        },
      }}
    />
  );
};

export { ProductFruitsProvider };
