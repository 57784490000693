import { Id, toast, ToastOptions } from "react-toastify";
import Cross from "@icons/cross.svg?react";

type MessageProps = {
  title: React.ReactNode;
  text?: React.ReactNode;
  severity: "success" | "error" | "info";
};

type _ToasterFn = (
  messageProps: Omit<MessageProps, "severity">,
  toastProps: ToastOptions,
) => Id;

type ToasterFn = _ToasterFn & {
  error: _ToasterFn;
  success: _ToasterFn;
};

// @ts-expect-error `closeToast` is passed by react-toastify
// eslint-disable-next-line react/prop-types
const Msg = ({ title, text, severity, closeToast }: MessageProps) => {
  const severityClasses: Record<MessageProps["severity"], string> = {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    error: "border-can-russet border",
    success: "",
    info: "",
  };
  return (
    <div
      className={`msg-container flex rounded-2xl bg-white p-4 font-sans text-sm shadow-can-light-box ${severityClasses[severity]}`}
    >
      <div className="flex flex-col">
        <p className="msg-title">{title}</p>
        <p className="msg-description">{text}</p>
      </div>
      <button onClick={closeToast} className="ml-auto">
        <Cross className="w-4- h-4" />
      </button>
    </div>
  );
};

const toaster: ToasterFn = (messageProps, toastProps) =>
  toast(<Msg {...messageProps} severity="info" />, { ...toastProps });

toaster.success = (messageProps, toastProps): Id =>
  toast.success(<Msg {...messageProps} severity="success" />, {
    ...toastProps,
  });

toaster.error = (messageProps, toastProps): Id =>
  toast.error(<Msg {...messageProps} severity="error" />, { ...toastProps });

export { Msg, toaster };
