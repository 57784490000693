import {
  Link,
  LinkProps,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

const SearchParamsLink = ({
  children,
  omit,
  ...props
}: LinkProps &
  React.PropsWithChildren<{ omit?: string[]; className?: string }>) => {
  let { search } = useLocation();
  let to = props.to;

  if (omit) {
    const params = new URLSearchParams(search);
    omit.forEach((param) => params.delete(param));
    search = "?" + params.toString();
  }

  if (typeof to !== "string") {
    to.search += search;
  } else {
    to += search;
  }
  return (
    <Link {...props} to={to}>
      {children}
    </Link>
  );
};

const MergeSearchParamsLink = ({
  children,
  ...props
}: LinkProps & React.PropsWithChildren) => {
  const [searchParams] = useSearchParams();
  let to = props.to;
  let linkParams = "";

  if (typeof to === "string") {
    const search = to.split("?")[1];

    if (search) {
      linkParams = search;
    }
  } else {
    if (to.search) {
      linkParams = to.search?.toString();
    }
  }

  const linkURLParams = createSearchParams(linkParams);

  for (const [key, value] of linkURLParams) {
    searchParams.delete(key);
    searchParams.set(key, value);
  }

  if (typeof to === "string") {
    to = "?" + to.slice(0, to.indexOf("?")) + searchParams.toString();
  } else {
    to.search = searchParams.toString();
  }

  return (
    <Link {...props} to={to}>
      {children}
    </Link>
  );
};

export { SearchParamsLink, MergeSearchParamsLink };
