import React from "react";
import invariant from "tiny-invariant";
import { Flag } from "../services/feature-flag";

const Context = React.createContext<Map<Flag, boolean> | undefined>(undefined);

const useFeatureFlag = () => {
  const context = React.useContext(Context);
  invariant(
    context,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "FeatureFlags are only available within <FeatureFlagProvider /> component",
  );

  return context;
};

const FeatureFlagProvider = ({
  children,
  flags,
}: React.PropsWithChildren<{ flags: Map<Flag, boolean> }>) => {
  return <Context.Provider value={flags}>{children}</Context.Provider>;
};

export { FeatureFlagProvider, useFeatureFlag };
