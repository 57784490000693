import GoogleSrc from "@icons/google.png";
import Key from "@icons/key.svg?react";

const UserIdentity = ({ identity }: { identity: string | string[] }) => {
  const _identity = Array.isArray(identity) ? identity[0] : identity;

  let identityIcon = <>?</>;

  if (_identity.startsWith("google")) {
    identityIcon = <img className="h-6 w-6 shrink-0" src={GoogleSrc} />;
  } else if (_identity.startsWith("Username-Password")) {
    identityIcon = <Key className="shrink-0" />;
  } else if (_identity.startsWith("auth0|")) {
    identityIcon = <Key className="shrink-0" />;
  }

  return identityIcon;
};

export { UserIdentity };
