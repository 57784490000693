import { useAuth0 } from "@auth0/auth0-react";
import { RouterProvider } from "react-router-dom";
import {
  createAnonymousRouter,
  createBlockedUserRouter,
  createIncompleteUserRouter,
  createRouter,
} from "./routes";
import { QueryProvider } from "@/common/providers/query-provider";
import { setGetAccessTokenSilently } from "@/common/services/auth";
import { FeatureFlagProvider } from "@/common/providers/feature-flag-provider";
import {
  getUserState,
  getUser,
  getMaybeUser,
  isBlocked,
} from "@/common/services/user";
import { I18nProvider } from "@/common/providers/i18n-provider";
import { getFlags } from "@/common/services/feature-flag";
import { defaultLanguage, fallbackLanguage } from "@/common/services/i18n";
import { Spinner } from "@/common/components/spinner/spinner";
import { DialogProvider } from "@/common/components/dialog/dialog";
import { setLocale } from "@/common/services/formatter";
import { ProductFruitsProvider } from "@/common/providers/product-fruits-provider";
import React from "react";
import { logger } from "@/common/services/logger";
import { ToastProvider } from "@/common/providers/toast-provider";
import { ToggleProvider } from "@/common/providers/toggle-provider";

const App = () => {
  const { getAccessTokenSilently, isLoading, user: _user } = useAuth0();
  setGetAccessTokenSilently(getAccessTokenSilently);

  React.useEffect(() => {
    if (_user) {
      logger.setUser({
        id: _user?.email,
        email: _user?.email,
        username: _user?.name,
      });
    } else {
      logger.setUser(null);
    }
  }, [_user]);

  if (isLoading) {
    return (
      <I18nProvider language={defaultLanguage}>
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </I18nProvider>
    );
  }

  if (!_user) {
    return (
      <I18nProvider language={defaultLanguage}>
        <DialogProvider>
          <RouterProvider router={createAnonymousRouter()} />
        </DialogProvider>
      </I18nProvider>
    );
  }

  const user = getMaybeUser(_user);
  const language =
    user.user_metadata?.can?.settings?.language ?? defaultLanguage;

  setLocale(language);

  if (isBlocked(user)) {
    return (
      <I18nProvider language={language}>
        <DialogProvider>
          <RouterProvider router={createBlockedUserRouter()} />
        </DialogProvider>
      </I18nProvider>
    );
  }

  if (getUserState(_user) === "complete") {
    const user = getUser(_user);
    const flags = getFlags(user);
    const company = user["can/app_metadata"]?.companies?.at(0);
    const language =
      user.user_metadata.can.settings?.language ?? fallbackLanguage;

    return (
      <ToggleProvider userId={user.email}>
        <I18nProvider language={language}>
          <ProductFruitsProvider
            language={language}
            user={{
              email: user.email,
              username: user.name,
              language,
              companyCountryCode: company?.countryCode,
              phoneCountryCode: user.user_metadata.common.phone.countryCode,
            }}
          />
          <FeatureFlagProvider flags={flags}>
            <QueryProvider>
              <DialogProvider>
                <RouterProvider router={createRouter(flags)} />
              </DialogProvider>
            </QueryProvider>
          </FeatureFlagProvider>
          <ToastProvider />
        </I18nProvider>
      </ToggleProvider>
    );
  }

  if (getUserState(_user) === "incomplete") {
    const user = getMaybeUser(_user);
    const language =
      user.user_metadata?.can?.settings?.language ?? fallbackLanguage;
    return (
      <ToggleProvider userId={user.email}>
        <I18nProvider language={language}>
          <QueryProvider>
            <DialogProvider>
              <RouterProvider router={createIncompleteUserRouter()} />
            </DialogProvider>
          </QueryProvider>
          <ToastProvider />
        </I18nProvider>
      </ToggleProvider>
    );
  }
};

export { App };
