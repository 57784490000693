import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { logger } from "../services/logger";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: logger.error.bind(logger),
  }),
  mutationCache: new MutationCache({
    onError: logger.error.bind(logger),
  }),
});

const QueryProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools buttonPosition="bottom-right" />
      {children}
    </QueryClientProvider>
  );
};

const oneHour = 3_600_000;
const fifteenMinutes = 900_000;

export { QueryProvider, oneHour, fifteenMinutes };
