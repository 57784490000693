import { queryOptions, useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { graphqlClient } from "@/api/graphql";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { sortString } from "@/common/utils/array";

type PostalCodes = z.infer<typeof postalCodesSchema>;

const postalCodesSchema = z.array(
  z.object({
    code: z.number(),
    name: z.string(),
    latitude: z.number(),
    longitude: z.number(),
  }),
);

const fetchData = async (signal: AbortSignal) => {
  const response = await fetch("/data/postal-codes-cz.json", { signal });
  const json = await response.json();
  const safeJson = postalCodesSchema.parse(json);

  return safeJson;
};

const postalCodesQuery = () =>
  queryOptions({
    queryKey: ["postal-codes"],
    queryFn: ({ signal }) => fetchData(signal),
    staleTime: Infinity,
    select: (data) =>
      data.map(({ code, name, ...rest }) => ({
        ...rest,
        code: code.toString(),
        name,
        label: `${code} ${name}`,
      })),
  });

// eslint-disable-next-line lingui/no-unlocalized-strings
const cropPricesQueryDocument = graphql(`
  query CropPricesRegion(
    $latitude: Float!
    $longitude: Float!
    $currency: Currency!
  ) {
    cropPricesInRegion(gps: { latitude: $latitude, longitude: $longitude }) {
      id
      crop {
        id
        name
      }
      quarter {
        id
        name
      }
      price(currency: $currency)
      date
    }
  }
`);

const cropPricesSchema = z.array(
  z.object({
    crop: z.object({
      id: z.string(),
      name: z.string(),
    }),
    prices: z.array(
      z.object({
        date: z.number(),
        id: z.string(),
        price: z.number(),
        quarter: z.object({ id: z.string(), name: z.string() }),
      }),
    ),
  }),
);

type CropPricesSchema = z.infer<typeof cropPricesSchema>;

const cropPricesQuery = (variables: {
  latitude: number;
  longitude: number;
  currency: Currency;
}) => {
  return queryOptions({
    queryKey: [
      "crop-prices",
      variables,
      variables.latitude,
      variables.longitude,
      variables.currency,
    ],
    queryFn: () => graphqlClient.request(cropPricesQueryDocument, variables),
    select: (data) => {
      const result: z.infer<typeof cropPricesSchema> = [];
      data.cropPricesInRegion?.sort((a, b) => {
        const aName = a?.crop?.name;
        const bName = b?.crop?.name;

        if (aName && bName) {
          return sortString(aName, bName);
        }

        return 0;
      });

      data.cropPricesInRegion?.forEach((price) => {
        const index = result.findIndex((y) => y.crop.id === price?.crop?.id);
        const { crop, ...rest } = price ?? {};

        if (index === -1) {
          result.push({
            // @ts-expect-error checked with zod
            crop,
            // @ts-expect-error checked with zod
            prices: [rest],
          });
        } else {
          // @ts-expect-error checked with zod
          result[index].prices.push(rest);
        }
      });

      return cropPricesSchema.parse(result);
    },
  });
};

const useIWantToTradeMutation = () => {
  const webhook = "https://hook.eu1.make.com/bd8iup9bstek66gvptsnupcm4msmrw7n";
  return useMutation({
    mutationFn: ({
      message,
      user,
      environment,
    }: {
      environment: string;
      message: string;
      user: { name: string; email: string };
    }) =>
      fetch(webhook, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          environment,
          user,
          message,
        }),
      }),
  });
};

export { postalCodesQuery, cropPricesQuery, useIWantToTradeMutation };
export type { PostalCodes, CropPricesSchema };
