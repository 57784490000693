import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "@/routes/layout";
import TimesHexagon from "@icons/times-hexagon.svg?react";
import Cross from "@icons/cross.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { Button } from "@/common/components/button/button";

type Props = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  action?: React.ReactNode | null;
  headerButton?: React.ReactNode;
};

const path = "failed";
const Failed = (props: Props) => {
  return (
    <FullscreenModalLayout
      headerButton={
        props.headerButton ?? (
          <SearchParamsLink to=".." replace>
            <Cross />
          </SearchParamsLink>
        )
      }
    >
      <Spacer className="h-6" />
      <h1 className="text-center text-2xl font-bold text-can-forest-teal">
        {props.title}
      </h1>
      <Spacer className="h-6" />
      <div className="self-center rounded-full bg-can-silver-gray p-11">
        <TimesHexagon />
      </div>
      <Spacer className="h-6" />
      <p className="text-center">{props.content}</p>
      <Button.Container>{props.action}</Button.Container>
    </FullscreenModalLayout>
  );
};

const Title = (props: React.PropsWithChildren) => (
  <h1 className="text-2xl font-black text-can-forest-teal" {...props} />
);

const Content = (props: React.PropsWithChildren) => (
  <p className="text-center" {...props} />
);

Failed.Content = Content;
Failed.Title = Title;

export { path, Failed };
