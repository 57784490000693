import { graphqlClient } from "@/api/graphql";
import { entitySchema } from "@/common/schema/entity";
import { graphql } from "@/generated/digitalnisklady.cz";
import { Currency } from "@/generated/digitalnisklady.cz/graphql";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { editOfferDocument } from "./edit-offer";
import invariant from "tiny-invariant";

// eslint-disable-next-line lingui/no-unlocalized-strings
const editStorageDocument = graphql(`
  mutation EditStorage($id: ID!, $storage: StorageInput!) {
    editStorage(id: $id, storage: $storage) {
      id
    }
  }
`);

// eslint-disable-next-line lingui/no-unlocalized-strings
const addOfferDocument = graphql(`
  mutation AddOffer($offer: OfferInput!) {
    addOffer(offer: $offer) {
      id
    }
  }
`);

type EditPayload = {
  name: string;
  companyId: string;
  address: string;
  zip: string;
};

const useEditStorage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: async (result) => {
      await queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "user" ||
          (query.queryKey[0] === "storage" &&
            query.queryKey[1] === result.editStorage?.id),
      });
    },
    mutationFn: async ({ id, storage }: { id: string; storage: EditPayload }) =>
      await graphqlClient.request(editStorageDocument, {
        id,
        storage: {
          address: storage.address,
          label: storage.name,
          company: storage.companyId,
          currency: Currency.Czk,
          zip: storage.zip,
        },
      }),
  });
};

const useAddCrop = () => {
  return useMutation({
    mutationFn: async (payload: {
      storageId: string;
      storage: {
        zip: string;
        label: string;
        crops: {
          id: string;
          tons: number;
        };
        companyId: string;
        address: string;
      };
      harvestYear: number;
    }) => {
      const existingOffers = await cropsAlreadyInStorage({
        storageId: payload.storageId,
      });
      invariant(existingOffers);

      const existingOffer = existingOffers.find(
        (offer) =>
          offer.harvestYear === payload.harvestYear &&
          offer.crop.id === payload.storage.crops.id,
      )?.offer;

      await graphqlClient.request(editStorageDocument, {
        id: payload.storageId,
        storage: {
          address: payload.storage.address,
          company: payload.storage.companyId,
          label: payload.storage.label,
          crops: [
            ...new Set([
              payload.storage.crops.id,
              ...existingOffers.map((offer) => offer.crop.id),
            ]),
          ],
          currency: Currency.Czk,
          zip: payload.storage.zip,
        },
      });

      if (existingOffer) {
        await graphqlClient.request(editOfferDocument, {
          id: existingOffer.id,
          offer: {
            storage: payload.storageId,
            crop: payload.storage.crops.id,
            // this is prone to race condition errors
            amount: payload.storage.crops.tons + existingOffer.totalAmount,
            yearHarvested: payload.harvestYear,
          },
        });
      } else {
        await graphqlClient.request(addOfferDocument, {
          offer: {
            storage: payload.storageId,
            crop: payload.storage.crops.id,
            amount: payload.storage.crops.tons,
            yearHarvested: payload.harvestYear,
          },
        });
      }
    },
  });
};

// eslint-disable-next-line lingui/no-unlocalized-strings
const storageOffersDocument = graphql(`
  query StorageOffers($id: ID!, $archived: Boolean) {
    storage(id: $id) {
      offers(archived: $archived) {
        edges {
          node {
            totalAmount
            id
            crop {
              id
              name
            }
            yearHarvested
          }
        }
      }
    }
  }
`);

const cropsAlreadyInStorageSchema = z.array(
  z.object({
    harvestYear: z.number(),
    crop: entitySchema,
    offer: z.object({ id: z.string(), totalAmount: z.number() }),
  }),
);

const cropsAlreadyInStorage = async ({
  storageId,
}: {
  storageId: string;
}): Promise<z.infer<typeof cropsAlreadyInStorageSchema>> => {
  const offers = await graphqlClient.request(storageOffersDocument, {
    id: storageId,
    archived: false,
  });

  const safeOffers = cropsAlreadyInStorageSchema.parse(
    offers.storage?.offers?.edges?.map((edge) => ({
      harvestYear: edge?.node?.yearHarvested,
      crop: edge?.node?.crop,
      offer: {
        totalAmount: edge?.node?.totalAmount,
        id: edge?.node?.id,
      },
    })),
  );

  return safeOffers;
};

export { useEditStorage, useAddCrop };
