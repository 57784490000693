import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "../layout";
import AngleLeft from "@icons/angle-left.svg?react";
import { Metadata } from "@/common/components/metadata/metadata";
import { Spacer } from "@/common/components/spacer/spacer";
import { z } from "zod";
import { entitySchema } from "@/common/schema/entity";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { OfferPropertiesList } from "@/common/components/offer-properties-list/offer-properties-list";
import { useCurrency } from "@/common/hooks/use-currency";
import { Button } from "@/common/components/button/button";
import { useAddOrderMutation } from "./trades.api";
import invariant from "tiny-invariant";
import { Fields, OrderForm } from "@/common/components/order-form/order-form";
import { path as successPath } from "../shared/routes/success/success";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";
import { useContractSignGuard } from "@/common/acl/statutory-guard/contract-sign-guard";

const path = "order";
const TradesDetailOrder = () => {
  const { _ } = useLingui();
  const { offerId } = useParams();
  invariant(offerId);
  const [, currency] = useCurrency();
  const context = useTradesDetailOrder();
  const navigate = useNavigate();
  const addOrder = useAddOrderMutation();
  const handleSubmit = (data: Fields) => {
    addOrder.mutate(
      {
        lowPrice: data.lowPricePerTon,
        price: data.pricePerTon,
        offerId,
        customTransport: data.customTransport,
        currency,
        note: data.note,
        amount: data.tons,
        quarterId: data.quarter,
      },
      {
        onSuccess: (mutation) => {
          navigate("../../" + successPath + "/order/" + mutation.addOrder?.id);
        },
      },
    );
  };
  const guardedSubmit = useContractSignGuard({ action: handleSubmit });

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink to=".." replace>
          <AngleLeft />
        </SearchParamsLink>
      }
    >
      <Metadata title={_(msg`Nastavit příkaz k prodeji`)} />
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Nastavit příkaz k prodeji</Trans>
      </h1>
      <Spacer className="h-6" />
      <OfferPropertiesList
        properties={[
          {
            label: <Trans>Komodita</Trans>,
            value: context.crop.name,
          },
          {
            label: <Trans>Farma</Trans>,
            value: context.company.name,
          },
          {
            label: <Trans>Sklad</Trans>,
            value: context.storage.name,
          },
        ]}
      />
      <Spacer className="h-6" />
      <OrderForm onSubmit={guardedSubmit} initialValues={{}}>
        <OrderForm.QuarterField />
        <OrderForm.LowPriceField currency={currency} />
        <OrderForm.PriceField currency={currency} />
        <OrderForm.TraderField />
        <OrderForm.AmountField />
        <OrderForm.CustomTransportField />
        <OrderForm.NoteField />
      </OrderForm>
      <Button.Container>
        <Button
          type="submit"
          disabled={addOrder.status === "pending"}
          form={OrderForm.formId}
          variant="primary"
        >
          <Trans>Nastavit příkaz</Trans>
        </Button>
      </Button.Container>
    </FullscreenModalLayout>
  );
};

const contextSchema = z.object({
  crop: entitySchema,
  company: entitySchema,
  storage: entitySchema,
  offer: z.object({
    amount: z.number(),
  }),
});

const useTradesDetailOrder = () => {
  const context = useOutletContext();
  return contextSchema.parse(context);
};

export { TradesDetailOrder, path };
