import { Helmet } from "react-helmet";
// @ts-expect-error how does one type virtual module for typescript?
import commitHash from "virtual:commit-hash";

const CommitHash = () => {
  return (
    <Helmet>
      <meta name="commit-hash" content={commitHash} />
    </Helmet>
  );
};

export { CommitHash };
