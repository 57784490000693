import React from "react";
import { useToggle } from "./use-toggle";
import { token } from "@/api/token";

const useUpdateToken = () => {
  const { on, toggle } = useToggle();

  const update = React.useCallback(async () => {
    toggle();
    await token.read({ forced: true });
    toggle();
  }, [toggle]);

  return [on, update] as const;
};

export { useUpdateToken };
