import { Dialog } from "@/common/components/dialog/dialog";
import { useToggle } from "@/common/hooks/use-toggle";
import { getUser } from "@/common/services/user";
import { Failed } from "@/routes/shared/routes/failed/failed";
import { useAuth0 } from "@auth0/auth0-react";
import { Trans } from "@lingui/macro";
import React from "react";
import invariant from "tiny-invariant";

const ContractSignContext = React.createContext<(() => void) | undefined>(
  undefined,
);

const useContractSignProvider = () => {
  const ctx = React.useContext(ContractSignContext);

  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "ContractSignContext only available within <ContratSignGuard />",
  );

  return ctx;
};

const ContractSignGuard = ({ children }: React.PropsWithChildren) => {
  const dialog = useToggle();

  return (
    <ContractSignContext.Provider value={dialog.setOn}>
      {children}
      {dialog.on ? (
        <Dialog>
          <Dialog.Title>
            <Trans>Akci nelze dokončit</Trans>
            <Dialog.Content>
              <Trans>
                Ve vaší společnosti nefiguruje uživatel s podpisovým právem.
              </Trans>
            </Dialog.Content>
            <Dialog.Content>
              <Trans>
                Kontaktujte podporu{" "}
                <a href="mailto:help@cleverfarm.ag">help@cleverfarm.ag</a>.
              </Trans>
            </Dialog.Content>
          </Dialog.Title>
          <Dialog.Actions>
            <Dialog.Confirm onClick={dialog.setOff}>
              <Trans>Zavřít</Trans>
            </Dialog.Confirm>
          </Dialog.Actions>
        </Dialog>
      ) : null}
    </ContractSignContext.Provider>
  );
};

const useContractSignGuard = <T = () => void,>({ action }: { action: T }) => {
  const raiseIssue = useContractSignProvider();
  const company = useFirstCompany();
  const canSign = company.users.some(
    (user) =>
      user.grants.includes("CONTRACT_SIGN") &&
      user.approvalState === "APPROVED",
  );

  if (canSign) {
    return action;
  }

  return raiseIssue;
};

const CanISignContractGuard = ({ children }: React.PropsWithChildren) => {
  const company = useFirstCompany();
  const canISign = company.grants.includes("CONTRACT_SIGN");

  if (canISign) {
    return <>{children}</>;
  }

  return (
    <Failed
      title={<Trans>Nemáte právo k podpisu smlouvy</Trans>}
      content={
        <Trans>Smlouvu může podepsat pouze uživatel s podpisovým právem</Trans>
      }
    />
  );
};

const useFirstCompany = () => {
  const { user: _user } = useAuth0();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(_user, "User should be defined");
  const user = getUser(_user);
  const company = user["can/app_metadata"]?.companies?.at(0);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(company, `User has no company defined`);

  return company;
};

export {
  CanISignContractGuard,
  useContractSignGuard,
  useContractSignProvider,
  ContractSignGuard,
};
