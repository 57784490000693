import { Metadata } from "@/common/components/metadata/metadata";
import { Spacer } from "@/common/components/spacer/spacer";
import Cross from "@icons/cross.svg?react";
import { useQuery } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import { storagesQuery } from "./api";
import { Spinner } from "@/common/components/spinner/spinner";
import { Failed, Info } from "@/common/components/info/info";
import Plus from "@icons/plus.svg?url";
import GarageOpenSvg from "@icons/garage-open.svg?react";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { ThreeStepProgressBar } from "./components/progress-bar/progress-bar";
import { FullscreenModalLayout } from "../layout";
import { path as newStoragePath } from "./storages.storage";
import { Button } from "@/common/components/button/button";
import { useCurrency } from "@/common/hooks/use-currency";
import { currencyFormatter } from "@/common/services/formatter";
import { sum } from "@/common/utils/array";
import { useLingui } from "@lingui/react";
import { Plural, Trans, msg } from "@lingui/macro";
import { CommodityCard } from "./components/commodity-card/commodity-card";

const path = "management";

const Management = () => {
  const { _ } = useLingui();
  const [, currency] = useCurrency();
  const storages = useQuery(storagesQuery({ currency }));
  let jsx: React.ReactNode = null;

  if (storages.status === "error") {
    jsx = <Failed error={storages.error} />;
  }

  if (storages.status === "pending") {
    jsx = (
      <div className="flex basis-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (storages.status === "success") {
    if (!storages.data) {
      jsx = (
        <Info>
          <Trans>
            Nenašli jsme žádnou společnost, ve které můžete spravovat sklady.
          </Trans>
        </Info>
      );
    } else {
      jsx = (
        <>
          {[...storages.data].map(([company, storages]) => (
            <section className="flex flex-col gap-y-4" key={company.id}>
              <h2 className="text-xl font-bold text-can-midnight-steel">
                {company.name}
              </h2>
              <ul className="mb-4 flex flex-col gap-y-6">
                {storages.map((storage) => {
                  const amountSum = storage.offers
                    .map((offer) => offer.amount)
                    .reduce(sum, 0);
                  const totalAmountSum = storage.offers
                    .map((offer) => offer.totalAmount)
                    .reduce(sum, 0);
                  const totalOrderSum = storage.offers
                    .map((offer) => offer.orderAmount)
                    .reduce(sum, 0);
                  const lastPriceSum = storage.offers
                    .map((offer) => (offer.lastPrice ?? 0) * offer.totalAmount)
                    .reduce(sum, 0);

                  return (
                    <li
                      key={storage.id}
                      className="flex flex-col rounded-2xl text-xs shadow-can-light-box"
                    >
                      <SearchParamsLink
                        to={storage.id}
                        className="flex flex-col gap-y-2 p-4 text-can-slate-blue-gray"
                      >
                        <h3 className="flex items-center gap-x-2 text-base font-bold text-can-forest-teal [&_path]:fill-[currentColor]">
                          <GarageOpenSvg className="h-4" />
                          {storage.name}
                        </h3>
                        <p>
                          <Trans>Skladem</Trans>:{" "}
                          <Plural
                            value={amountSum}
                            _0="# tun"
                            one="# tuna"
                            few="# tuny"
                            many="# tun"
                            other="# tun"
                          />{" "}
                          | <Trans>Hodnota (nyní)</Trans>:{" "}
                          {currencyFormatter(currency).format(lastPriceSum)}
                        </p>
                        <ThreeStepProgressBar
                          values={{
                            orderAmount: totalOrderSum,
                            totalAmount: totalAmountSum,
                            freeAmount: amountSum,
                          }}
                        />
                        <CommodityCard.Available
                          available={amountSum}
                          order={totalOrderSum}
                          tons={totalAmountSum}
                        />
                      </SearchParamsLink>
                    </li>
                  );
                })}
              </ul>
            </section>
          ))}
          <Button.Container>
            <SearchParamsLink
              to={newStoragePath}
              style={{
                // eslint-disable-next-line lingui/no-unlocalized-strings
                background: `url(${Plus}) left 16px center no-repeat`,
              }}
              className="mt-auto rounded-2xl border border-can-forest-teal px-4 py-3 text-center text-can-forest-teal"
            >
              <span>
                <Trans>Přidat sklad</Trans>
              </span>
            </SearchParamsLink>
          </Button.Container>
        </>
      );
    }
  }

  return (
    <>
      <FullscreenModalLayout
        headerButton={
          <SearchParamsLink to=".." replace>
            <Cross />
          </SearchParamsLink>
        }
      >
        <Metadata title={_(msg`Spravovat sklady`)} />
        <Spacer className="h-6" />
        <h1 className="text-2xl font-black text-can-forest-teal">
          <Trans>Spravovat sklady</Trans>
        </h1>
        <Spacer className="h-6" />
        {jsx}
      </FullscreenModalLayout>
      <Outlet />
    </>
  );
};

export { path, Management };
