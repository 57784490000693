import { UserPatchRequest, getLanguages, patch } from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";

const useUpdateUserSettingsMutation = () =>
  useMutation({
    mutationKey: ["user", "update"],
    mutationFn: (params: UserPatchRequest) => patch(params),
  });

const appLanguagesQuery = () =>
  queryOptions({
    queryKey: ["app-languages"],
    queryFn: ({ signal }) => getLanguages({ signal }),
  });

export { useUpdateUserSettingsMutation, appLanguagesQuery };
