import { oneHour } from "@/common/providers/query-provider";
import {
  ReportListReportTo,
  getReports,
  ReportPatchTo,
  deleteReport,
  patchReport,
} from "@/generated/api/news";
import { t } from "@lingui/macro";
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { z } from "zod";

type Params = z.infer<typeof reportsQueryParamsSchema>;

const reportsQueryParamsSchema = z
  .object({
    "sort-order": z.enum(["ASC", "DESC"]),
    "date-from": z
      .string()
      .regex(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
      .nullish()
      .transform((v) => v ?? undefined),
    "date-to": z
      .string()
      .regex(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)
      .refine((date) => DateTime.now() > DateTime.fromISO(date), {
        message: (() => t`End date needs to come before today.`)(),
      }),
  })
  .refine(
    (data) => {
      if (!data["date-from"]) {
        return true;
      }
      return data["date-to"] > data["date-from"];
    },
    {
      message: (() => t`End date needs to come after start date.`)(),
    },
  );

type ReportsQueryResult = Omit<ReportListReportTo, "id" | "language"> & {
  id: string;
};

const reportsQuery = (params: Params) =>
  queryOptions({
    staleTime: oneHour,
    queryKey: ["reports", params],
    queryFn: async ({ signal }) => {
      const safeParams = reportsQueryParamsSchema.parse(params);

      return await getReports(safeParams, { signal });
    },
    select: (data) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data.reports.map(({ id, language, ...rest }) => ({
        ...rest,
        id: id.toString(),
      })),
  });

const reportsDateRangeQuery = (params: Params) =>
  queryOptions({
    // because we do not care about the reports data itself
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ["reports-date-range"],
    queryFn: async ({ signal }) => {
      const safeParams = reportsQueryParamsSchema.parse(params);

      return await getReports(safeParams, { signal });
    },
    staleTime: Infinity,
    select: (data) => ({
      oldest: data.first ? DateTime.fromISO(data.first) : undefined,
      newest: data.last ? DateTime.fromISO(data.last) : undefined,
    }),
  });

const deleteReportMutation = () => {
  return {
    mutationFn: (id: number) => deleteReport(id),
  };
};

const changeReportStatusMutation = () => {
  return {
    mutationFn: ({ id, status }: { id: number } & ReportPatchTo) =>
      patchReport(id, { status }),
  };
};

export {
  reportsQuery,
  reportsDateRangeQuery,
  reportsQueryParamsSchema,
  deleteReportMutation,
  changeReportStatusMutation,
};
export type { Params, ReportsQueryResult };
