import { Outlet, useLocation } from "react-router-dom";
import { SideBar } from "@/common/components/side-bar/side-bar";
import { TopBar } from "@/common/components/top-bar/top-bar";
import { UserProfile } from "@/common/components/user-profile/user-profile";
import { useSidebar } from "@/common/providers/sidebar-provider";
import { UserSettings } from "@/common/components/user-settings/user-settings";
import { Suspense } from "react";
import { Spinner } from "@/common/components/spinner/spinner";
import { ErrorBoundary } from "react-error-boundary";
import { Failed } from "@/common/components/info/info";
import Cross from "@icons/cross.svg?react";
import { NotificationList } from "@/common/components/push-notifications/notification-list";
import React from "react";
import { useScrollRestoration } from "@/common/hooks/use-scroll-restoration";

const Layout = () => {
  const location = useLocation();
  const Jsx = (
    <>
      <TopBar />
      <UserProfile />
      <SideBar key={`sidebar-${location.pathname}`} />
    </>
  );

  return (
    <>
      {Jsx}
      <div className="can-sm-layout ml-6 flex h-full pt-8 has-[>_:nth-child(1):last-child]:mr-6 sm:ml-[calc(theme(spacing.20)+theme(spacing.6))] sm:pt-14">
        <Outlet />
      </div>
    </>
  );
};

const TwoColumnLayout = ({
  left,
  right,
}: {
  left: {
    header: React.ReactNode;
    content: React.ReactNode;
  };
  right: boolean;
}) => {
  const sidebar = useSidebar();
  const isSidebarOpen = !!sidebar.state.screen;
  const hasRightSide = right || isSidebarOpen;
  const { handleLinkClick, focusClickedElement, handleScroll } =
    useScrollRestoration(hasRightSide);

  focusClickedElement();

  return (
    <>
      <div
        onClick={handleLinkClick}
        className="mt-14 flex w-full flex-grow flex-col"
      >
        <div className={`${hasRightSide ? "mr-6" : ""} flex justify-between`}>
          {left.header}
        </div>
        <div
          onScroll={handleScroll}
          className={` flex w-full basis-full flex-col gap-y-6  pt-4 ${
            hasRightSide ? "overflow-y-auto pr-6" : ""
          }
`}
        >
          {left.content}
        </div>
      </div>
      {hasRightSide ? (
        <aside className="border-can-can-silver-cloud grid max-w-[500px] overflow-y-auto sm:mt-7 sm:border-l lg:min-w-[600px] lg:max-w-[750px]">
          <Suspense
            fallback={
              <FullscreenModalLayout headerButton={null}>
                <Spinner />
              </FullscreenModalLayout>
            }
          >
            <Outlet />
          </Suspense>
          {isSidebarOpen ? (
            <ErrorBoundary
              fallbackRender={({ error }) => (
                <FullscreenModalLayout
                  headerButton={
                    <button
                      onClick={() => sidebar.dispatch({ type: "toggle" })}
                    >
                      <Cross />
                    </button>
                  }
                >
                  <Failed error={error} />
                </FullscreenModalLayout>
              )}
            >
              <Suspense
                fallback={
                  <FullscreenModalLayout headerButton={null}>
                    <Spinner />
                  </FullscreenModalLayout>
                }
              >
                {sidebar.state.screen === "notifications" ? (
                  <NotificationList />
                ) : (
                  <UserSettings />
                )}
              </Suspense>
            </ErrorBoundary>
          ) : null}
        </aside>
      ) : null}
    </>
  );
};

const FullscreenModalLayout = ({
  children,
  headerButton,
}: React.PropsWithChildren<{ headerButton: React.ReactNode }>) => {
  return (
    <article
      style={{ gridRow: 1, gridColumn: 1 }}
      className="shrink-1 absolute inset-0 z-[1] flex flex-grow flex-col overflow-y-auto bg-white px-6 lg:static lg:-z-[0]"
    >
      <span className="mt-9 self-start">{headerButton}</span>
      {children}
    </article>
  );
};

export { Layout, TwoColumnLayout, FullscreenModalLayout };
