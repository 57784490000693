import { createSearchParams, useSearchParams } from "react-router-dom";
import { z } from "zod";

const currencySchema = z.object({
  currency: z.enum(["CZK", "EUR"]),
});

const initialCurrency = {
  currency: "CZK",
};

const initialParams = createSearchParams({
  currency: "CZK",
});

const useCurrencyParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = currencySchema.safeParse(
    Object.fromEntries(searchParams),
  );

  return {
    params: parsedParams.success ? parsedParams.data : undefined,
  };
};

export { useCurrencyParams, initialParams, currencySchema, initialCurrency };
